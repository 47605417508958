import styled from 'styled-components'
import { Button as Base } from 'rebass'

const Button = styled(Base)`
  border-radius: 2px;
  text-transform: uppercase;
  background-color: ${props => {
    if (props.green) {
      return 'rgb(17, 204, 119)';
    } else if (props.gray) {
      return 'rgb(136, 136, 136)';
    } else if (props.orange){
      return 'rgb(255, 85, 34)';
    } else if (props.transparent){
      return 'transparent';  
    } else {
      return 'rgb(0, 136, 238)';
    }
  }};
`

export default Button
