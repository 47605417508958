import { WebSocketLink } from "apollo-link-ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { ApolloClient } from 'apollo-client';
import { RetryLink } from 'apollo-link-retry';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { withClientState } from 'apollo-link-state';
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { hashHistory } from 'react-router';
import sjcl from 'sjcl';
import moment from 'moment';
import { token as getToken } from './auth';
import config, { getEnvSpecificConfiguration } from './config';
import { ApolloLink, split, Observable } from 'apollo-link';
import { token } from './components/temp/auth';
import introspectionQueryResultData from './schema/fragmentTypes.json';


const wsClient = new W3CWebSocket(`${getEnvSpecificConfiguration(window.location.host).wsApiEndpoint}?Authorization=${getToken()}`, null, null, null);

function isTokenExpired(userToken) {
  const now = moment().utc();
  const payload = userToken.split('.')[1];
  const idtokenExpiration = JSON.parse(sjcl.codec.utf8String.fromBits(sjcl.codec.base64.toBits(payload)));
  
  return now.isAfter(moment.unix(idtokenExpiration.exp).subtract(30, 'minutes'));
}

const subscriptionClient = new SubscriptionClient(getEnvSpecificConfiguration(window.location.host).wsApiEndpoint, {
  reconnect: true,
  lazy: true,
}, wsClient, []);

const wsLink = new WebSocketLink(subscriptionClient);



const createApolloClient = (token) => {

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  });
  
  
  const cache = new InMemoryCache({
    fragmentMatcher,
    dataIdFromObject: object => object.id,
    cacheRedirects: {
      Query: {
      }
    }
  });
  
  const request = async (operation) => {
    const newToken = getToken();
    operation.setContext({
      headers: {
        authorization: newToken ? newToken : token,
        "accept-language": localStorage.getItem('language') || 'en',
      }
    });
  };
  
const getMainDefinition = query => query.definitions.filter( d => d.kind === 'OperationDefinition');
  

  const requestLink = new ApolloLink((operation, forward) =>
    new Observable(observer => {
      let handle: any;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));
  
      return () => {
        if (handle) handle.unsubscribe;
      };
    })
  );
  const principal ={
    userId: "SJu4I7k_Z",
    defaultRole: '5'
  } // JSON.parse(localStorage.getItem('authenticatedUser'))
  const httpLink = ApolloLink.from([

      new TokenRefreshLink({
        isTokenValidOrUndefined: () => !isTokenExpired(token),

        fetchAccessToken: () => {
          return fetch("https://cognito-idp.us-east-1.amazonaws.com/", {      headers: {    "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",    
            "Content-Type": "application/x-amz-json-1.1", 
          },    
          mode: 'cors',    
          cache: 'no-cache',    
          method: 'POST',    
          body: JSON.stringify({    
            ClientId: getEnvSpecificConfiguration(window.location.host).cognitoClientId,    
            AuthFlow: 'REFRESH_TOKEN_AUTH',    
            AuthParameters: {    
              REFRESH_TOKEN: localStorage.getItem('refreshToken'),    
            }    
          }),   
          })
        },
        handleFetch: accessToken => {
       
          const auth = JSON.parse(localStorage.getItem(config.localStorageAuthenticatedUserKey));

          if (auth) {
            auth.token = accessToken;

            localStorage.setItem(config.localStorageAuthenticatedUserKey, JSON.stringify(auth));
          }
        },

        handleResponse: (operation, accessTokenField) => async response => {
          // here you can parse response, handle errors, prepare returned token to
          // further operations
          const res = await response.json();
          const newToken = res.AuthenticationResult.IdToken;
          // returned object should be like this:
          return {
             access_token: newToken
          }
        },
        handleError: err => {
          console.log(err);
          console.warn('Your refresh token is invalid. Try to relogin');    
          // hashHistory.push('/logout');
        }
      }),

    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        // sendToLoggingService(graphQLErrors);
        console.log(graphQLErrors);
      }
      if (networkError) {
        // logoutUser();
        console.log(networkError);
      }
    }),
    requestLink,
    withClientState({
      defaults: {
        isConnected: true
      },
      resolvers: {
        Mutation: {
          updateNetworkStatus: (_, { isConnected }, { cache }) => {
            cache.writeData({ data: { isConnected }});
            return null;
          }
        }
      },
      cache
    }),
    new HttpLink({
      uri: getEnvSpecificConfiguration(window.location.host).graphQlApiEndpoint,
      // headers:{
      //   "Principal": `${principal.userId}|${principal.defaultRole}|`
      // }
    })
  ]);

  const client = new ApolloClient({
      link: new RetryLink({
        attempts: {
          max: 5,
          retryIf: (error, _operation) => {
            if (
              _operation.operationName === "insertAppointmentRequest" && 
              error.message.includes('504') ||
              _operation.operationName === "updateAppointmentRequestStatus" && 
              error.message.includes('504') ||
              _operation.operationName === "insertAppointmentReschedule" && 
              error.message.includes('504')
            ) {
              return false;
            } else {
              return !!error
            }
          }
        }
      }).split(

        ({ query }) => { 
          const definition = getMainDefinition(query);
          return (
            definition &&
            definition.operation === 'subscription'
          );
        },

        wsLink,
        httpLink
      ),
      cache,
      connectToDevTools: process.env.NODE_ENV === 'development',
    });
    return client;
}

export default createApolloClient;
