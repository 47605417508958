/* @flow */
/* globals AWS, AWSCognito */
import * as auth from '.';
import config from '../config';
import userIsSpaCM from '../cognito/isSpaCM';
import {getRedirectNonOnboardedUserUrls} from '../common/onboarding/redirectNonOnboardedUsers';

const urls = {
  login: '/login',
  spAdminHome: '/admin',
  participantHome: '/me',
  signeula: '/signeula',
  signInitialService: '/acceptinitialservice'
};

const PARTICIPANT_ROLE = 5;
const SP_ADMIN_ROLE = '3';
const identityProviderKey = `cognito-idp.${config.awsRegion}.amazonaws.com/${config.cognitoUserPoolId}`;

export default function authenticateOnEnter(nextState: any, replace: any) {
  const { pathname, query } = nextState.location;
  const token = auth.token();
  const roles = auth.getUserRoles() || '';
  const defaultRole = auth.getDefaultRole() || '3';

  const isParticipant = parseInt(roles, 10) === PARTICIPANT_ROLE;

  const url = window.location.href;

  let isGoingToParticipantRoute = false; 
  let isGoingToSPARoute = false; 
 
  const pIdentifier = '/#/me';
  const participantCheckIndex = url.indexOf(pIdentifier);

  if (participantCheckIndex !== -1) {
    const participantCheck = url.slice(participantCheckIndex + pIdentifier.length);
    if (participantCheck.length === 0 || participantCheck.charAt(0) === '/') {
      isGoingToParticipantRoute = true;
    }
  }

  const spaIdentifier = '/#/admin';
  const spaCheckIndex = url.indexOf(spaIdentifier);

  if (spaCheckIndex !== -1) {
    const spaCheck = url.slice(spaCheckIndex + spaIdentifier.length);
    if (spaCheck.length === 0 || spaCheck.charAt(0) === '/') {
      isGoingToSPARoute = true;
    }
  }

  const isSwitchingToCM = Boolean(query.switchtocm);
  let switchmode = localStorage.getItem('switchmode');

  if (!token) {
    return replace(urls.login);
  }
  const onboardingFlags =  JSON.parse(
    localStorage.getItem('onboardingFlags')
  );

  // const redirectUrl = getRedirectNonOnboardedUserUrls(onboardingFlags ,roles)

  if (!onboardingFlags.isEulaAccepted){
    return replace(urls.signeula);
  }

  if (isParticipant && onboardingFlags.isPromptedOnboardServiceFlow){
    return replace(urls.signInitialService);
  }

  // get the user role and route according to user role.
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.cognitoIdentityPoolId,
    Logins: {
      [identityProviderKey]: auth.removeExtraAttributesFromToken(token),
    },
  });
  // if SPA/CM
  // if user is switching roles from SPA->CM or CM->SPA, do nothing
  if (userIsSpaCM() && isSwitchingToCM) {
      return true;
  }

  // if a default role is assigned then redirect to the default role dashboard
  if (userIsSpaCM() && defaultRole == '3' && switchmode !== 'cm') {
    // if going to Participant Route, redirect
    if (isGoingToParticipantRoute) {
      return replace(urls.spAdminHome);
    }
  }

  if (parseInt(roles, 10) === PARTICIPANT_ROLE && !isGoingToParticipantRoute) {
    return replace(urls.participantHome);
  }

  // user with SPA role
  if (roles.indexOf(SP_ADMIN_ROLE) !== -1 && defaultRole == '3'
        && switchmode !== 'cm' && !isGoingToSPARoute) {
    return replace(urls.spAdminHome);
  }
  return false;
}
