import gql from "graphql-tag";

const UPDATE_SERVICE_STATUS = gql`
  mutation updateServiceStatus($input: UpdateServiceStatusInput!) {
    updateServiceStatus(input: $input) {
      ... on UpdateServiceStatusPayload {
        service {
          id
          status
          organization {
            id
            ... on ServiceProvider {
              hasServices
              hasServicesWithCMs
              hasServicesWithFacilities
            }
          }
        }
      }
    }
  }
`;

export default UPDATE_SERVICE_STATUS;
