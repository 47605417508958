import React from 'react';
import { Route, IndexRoute, IndexRedirect } from 'react-router';
import Loadable from 'react-loadable';
import Loading from '../common/loading/Loading';
import staticRoutes from './staticRoutes';
import selfProfileRoutes from './selfProfileRoutes';

// loadable planRoutes
const LoadableTreatmentPlan = Loadable({
  loader: () => import('../wilfred/plan/TreatmentPlan'),
  loading: Loading,
});

const LoadableTreatmentPlanTopLevel = Loadable({
  loader: () => import('../wilfred/plan/all-goals/TreatmentPlanTopLevel'),
  loading: Loading,
});

const LoadableUnsharingTreatmentPlanTopLevel = Loadable({
  loader: () => import('../wilfred/plan/all-goals/UnsharingTreatmentPlanTopLevel'),
  loading: Loading,
});

const LoadableFilesByService = Loadable({
  loader: () => import('../wilfred/files/FilesByService'),
  loading: Loading,
});

const LoadableTreatmentPlanTaskLevel = Loadable({
  loader: () => import('../wilfred/plan/task-details/TreatmentPlanTaskLevel'),
  loading: Loading,
});

const LoadableTreatmentPlanTaskFileManager = Loadable({
  loader: () => import('../wilfred/plan/TreatmentPlanTaskFileManager'),
  loading: Loading,
});

const LoadableStandAloneReminder= Loadable({
  loader: () => import('../wilfred/reminders/StandAloneReminder'),
  loading: Loading,
});

// loadable resourcesRoutes
const LoadableNeedsAssessment = Loadable({
  loader: () => import('../wilfred/needs/NeedsAssessment'),
  loading: Loading,
});

const LoadableSelectNeedCategories = Loadable({
  loader: () => import('../wilfred/needs/select-categories/SelectNeedCategories'),
  loading: Loading,
});

const LoadableSetMyLocation = Loadable({
  loader: () => import('../wilfred/needs/set-location/SetMyLocation'),
  loading: Loading,
});

const LoadablePreScreenQuestions = Loadable({
  loader: () => import('../wilfred/needs/prescreen-questions/PreScreenQuestions'),
  loading: Loading,
});

const LoadableMatchingServices = Loadable({
  loader: () => import('../wilfred/needs/view-services/MatchingServices'),
  loading: Loading,
});

const LoadableRequestReferral = Loadable({
  loader: () => import('../wilfred/needs/request-referral/RequestReferral'),
  loading: Loading,
});

// loadable filesRouter

const LoadableFilesContainer = Loadable({
  loader: () => import('../wilfred/files/FilesContainer'),
  loading: Loading,
});

const LoadableFiles = Loadable({
  loader: () => import('../wilfred/files/Files'),
  loading: Loading,
});

const LoadableFilesForNeedCategory = Loadable({
  loader: () => import('../wilfred/files/FilesForNeedCategory'),
  loading: Loading,
});

// loadable default routes

const LoadableAppContainer = Loadable({
  loader: () => import('../wilfred/ParticipantAppContainer'),
  loading: Loading,
});

const LoadableDashboard = Loadable({
  loader: () => import('../wilfred/dashboard/Dashboard'),
  loading: Loading,
});

const LoadableUserDocumentViewer = Loadable({
  loader: () => import('../common/files/UserDocumentViewer'),
  loading: Loading,
});

const LoadableHelp = Loadable({
  loader: () => import('../common/help/Help'),
  loading: Loading,
});

const LoadableFormViewer = Loadable({
  loader: () => import('../common/forms/FormViewer'),
  loading: Loading,
});

const LoadableEventsContainer = Loadable({
  loader: () => import('../wilfred/events/Calendar'),
  loading: Loading,
});

const LoadableMessageThreads = Loadable({
  loader: () => import('../common/messages/MessageThreads'),
  loading: Loading,
});

const LoadableMessageExchange = Loadable({
  loader: () => import('../common/messages/MessageExchange'),
  loading: Loading,
});


const LoadableVideo = Loadable({
  loader: () => import('../common/video/VideoScreen'),
  loading: Loading,
});

const LoadableSelectPeople = Loadable({
  loader: () => import('../common/messages/SelectPeople'),
  loading: Loading,
});

const LoadableNotifications = Loadable({
  loader: () => import('../common/notifications/Notifications'),
  loading: Loading,
});

const LoadableParticipantSelfProfile = Loadable({
  loader: () => import('../wilfred/profile/ParticipantSelfProfile'),
  loading: Loading,
});

const LoadableCommunityServiceHours = Loadable({
  loader: () => import('../wilfred/profile/CommunityServiceHours'),
  loading: Loading,
});

const LoadableDemographicInfo = Loadable({
  loader: () => import('../wilfred/profile/DemographicInfo'),
  loading: Loading,
});

const LoadableWorkHistory= Loadable({
  loader: () => import('../wilfred/profile/ParticipantViewingWorkHistory'),
  loading: Loading,
});

const LoadableCheckInViewer = Loadable({
  loader: () => import('../common/view-checkins/CheckInViewer'),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.checkInViewer;
    return <Component {...props}/>;
  }
});

// const LoadableCheckInViewer = Loadable({
//   loader: () => import('../common/view-checkins/CheckInViewer'),
//   loading: Loading,
// });

const LoadableViewParticipantServices = Loadable({
  loader: () => import('../wilfred/profile/ViewParticipantServices'),
  loading: Loading,
});


const LoadableViewReferralHistory = Loadable({
  loader: () => import('../common/referrals/ParticipantReferralHistory'),
  loading: Loading,
});

const LoadableViewParticipantReferral = Loadable({
  loader: () => import('../common/referrals/ParticipantViewReferral'),
  loading: Loading,
});

// contacts routes

const LoadableContactsContainer = Loadable({
  loader: () => import('../wilfred/contacts/ContactsContainer'),
  loading: Loading,
});

const LoadableContactDetails = Loadable({
  loader: () => import('../wilfred/contacts/ContactDetails'),
  loading: Loading,
});

const LoadableContactProfileHome = Loadable({
  loader: () => import('../wilfred/contacts/CMProfileHeader'),
  loading: Loading,
});

const LoadableAppointmentsContainer = Loadable({
  loader: () => import('../common/appointments/AppointmentsContainer'),
  loading: Loading,
});

const LoadableAppointmentsList = Loadable({
  loader: () => import('../common/appointments/AppointmentsList'),
  loading: Loading,
});

const LoadableOldAppointmentsList = Loadable({
  loader: () => import('../common/appointments/OldAppointmentsList'),
  loading: Loading,
});

const LoadableAppointment= Loadable({
  loader: () => import('../common/appointments/Appointment'),
  loading: Loading,
});

const LoadableAnnouncement = Loadable({
  loader: () => import('../common/announcements/Announcement'),
  loading: Loading,
});

const LoadableHelpVideos = Loadable({
  loader: () => import('../common/help-videos/HelpVideos'),
  loading: Loading,
});

const LoadableReporting = Loadable({
  loader: () => import('../common/reporting/Reporting'),
  loading: Loading,
});

const LoadableActOnBehalf = Loadable({
  loader: () => import('../wilfred/guardian/act-on-behalf/ActOnBehalf'),
  loading: Loading,
});

const planRoutes = (
  <Route path="plan" component={LoadableTreatmentPlan} >
    <IndexRoute component={LoadableTreatmentPlanTopLevel} />
    <Route path="unsharing" component={LoadableUnsharingTreatmentPlanTopLevel} />
    <Route path="unsharing/files/:id" component={LoadableFilesByService} />
    <Route path="tasks/:id" component={LoadableTreatmentPlanTaskLevel} />
    <Route path="tasks/:id/filemanager" component={LoadableTreatmentPlanTaskFileManager} />
  </Route>
);

const resourcesRoutes = (
  <Route path="resources" component={LoadableNeedsAssessment} >
    <IndexRoute component={LoadableSelectNeedCategories} />
    <Route path="location" component={LoadableSetMyLocation} />
    <Route path="prescreen" component={LoadablePreScreenQuestions} />
    <Route path="matches" component={LoadableMatchingServices} />
    <Route path="referral/service/:id" component={LoadableRequestReferral} />
  </Route>
);

const filesRoutes = (
  <Route path="files" component={LoadableFilesContainer} >
    <IndexRoute component={LoadableFiles} />
    <Route path=":needCategory" component={LoadableFilesForNeedCategory} />
  </Route>
);

const contactsRoutes = (
  <Route path="contacts" component={LoadableContactsContainer}>
    <IndexRedirect to="/me" />
    <Route path=":id" component={LoadableContactDetails} >
      <IndexRoute component={LoadableContactProfileHome} />
      <Route path="messagingwith/:pokketUserId" component={LoadableMessageThreads} />
      <Route archived={true} path="archivedmessagingwith/:pokketUserId" component={LoadableMessageThreads} />
      <Route path="messageexchange/:threadId" component={LoadableMessageExchange} />
      <Route path="appointments" component={LoadableAppointmentsContainer}>
        <IndexRoute component={LoadableAppointmentsList} />
        <Route path="past" component={LoadableOldAppointmentsList} />
        <Route path="details" component={LoadableTreatmentPlan} >
          <Route path=":appointmentId" component={LoadableAppointment} />
        </Route>
      </Route>
    </Route>
  </Route>
  );

const participantRoutes = (loginner) => (
  <Route path="/me" onEnter={loginner} component={LoadableAppContainer} >
    <IndexRedirect to="home" />
    <Route path="home" component={LoadableDashboard} />
    <Route path="documents/:id/view" component={LoadableUserDocumentViewer} />
    {contactsRoutes}
    {filesRoutes}
    {planRoutes}
    {resourcesRoutes}
    {staticRoutes}
    <Route path="actonbehalf" component={LoadableActOnBehalf} />
    <Route path="appointments" component={LoadableAppointmentsContainer}>
      <Route path="details" component={LoadableTreatmentPlan} >
          <Route path=":appointmentId" component={LoadableAppointment} />
      </Route>
    </Route>
    <Route path="help" component={LoadableHelp} />
    <Route path="helpvideos" component={LoadableHelpVideos} />
    <Route path="/forms/:id/view" component={LoadableFormViewer} />
    <Route path="calendar" component={LoadableEventsContainer} />
    <Route path="messages" component={LoadableMessageThreads} />
    <Route path="reminders/:id" component={LoadableStandAloneReminder} />
    <Route archived={true} path="archivedmessages" component={LoadableMessageThreads} />
    <Route path="messageexchange/:id" component={LoadableMessageExchange} />
    <Route path="messages/selectpeople/:id/:pokketUserId" component={LoadableSelectPeople} />
    <Route path="notifications" component={LoadableNotifications} />
    <Route path="announcement/:id" component={LoadableAnnouncement} />
    <Route path="profile" component={LoadableParticipantSelfProfile}>
      {selfProfileRoutes}
      <Route path="communityservice" component={LoadableCommunityServiceHours} />
      <Route path="workhistory" component={LoadableWorkHistory} />
      <Route path="demographics" component={LoadableDemographicInfo} />
      <Route path="checkins" component={LoadableCheckInViewer} />
      <Route path="viewservices" component={LoadableViewParticipantServices} />
      <Route path="viewservices/referralhistory" component={LoadableViewReferralHistory} />
      <Route path="viewservices/referral/:referralId" component={LoadableViewParticipantReferral} />
      <Route path="reporting" component={LoadableReporting} />
    </Route>
  </Route>
);

export default participantRoutes;
