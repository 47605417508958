/* @flow */
import { hashHistory } from 'react-router';
import onboardingPaths from './onboardingPaths';

type Flags = {
  isOwnPhone: boolean,
  isEulaAccepted: boolean,
  isTempPasswordReset: boolean,
  isInitialProfileCompleted: boolean,
  isOrgProfileCompleted?: boolean,
  isCaseReassignmentAcknowledged: boolean,
  isInitialSmsOptInAcknowledged: boolean,
  isAutoshareUnacknowledged: boolean,
};

export function getRedirectNonOnboardedUserUrls(flags, userRole, reqFieldNull, firstOnboard ) {
  const userIsParticipant = (userRole === "participant");

  const urls = onboardingPaths[userRole];
  if (!flags.isEulaAccepted) {
    return urls.signEula;
  } else if (flags.isAutoshareUnacknowledged) {
    return urls.acknowledgeAutoshare;
  } else if (!flags.isCaseReassignmentAcknowledged) {
    return urls.ackCaseReassignment;
  } else if (!flags.isTempPasswordReset) {
    return urls.resetPassword;
  } else if (flags.isOwnPhone &&
            !flags.isInitialSmsOptInAcknowledged) {
   return urls.introduceSmsOptIn;
  } else if (userIsParticipant) { 
    if (firstOnboard || !flags.isInitialProfileCompleted && localStorage.getItem('isGuardian')) {
      return urls.contactInformation;
    } else if (reqFieldNull || !flags.isInitialProfileCompleted) {
      return urls.demographicInfo;
    } 
  } else if (!flags.isInitialProfileCompleted) {
    return urls.contactInformation;
  }


  if (userRole === 'sp_admin' && !flags.isOrgProfileCompleted) {
    return onboardingPaths.sp_admin.editOrgProfile;
  }

  return null;
}

// TODO can refactor to simply take viewer and read off role and flags.
export default function redirectNonOnboardedUsers(flags: Flags, userRole: String, reqFieldNull, firstOnboard)  {

  const url = getRedirectNonOnboardedUserUrls(flags, userRole, reqFieldNull, firstOnboard);
  if(url){
    return hashHistory.replace(url);
  }

  return true;
}
