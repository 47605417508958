/* @flow */
import { CognitoUser } from 'amazon-cognito-identity-js';
import getCognitoUserPool from './getCognitoUserPool';

export default function getCognitoUser(username: string) {
  return new CognitoUser({
    Username: username.toLowerCase(),
    Pool: getCognitoUserPool(),
  });
}
