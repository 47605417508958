import i18n from "i18next";
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(resourcesToBackend((language, namespace, callback) => {
    import(`./locales/${language}/${namespace}.js`)
      .then((resources) => {
        callback(null, resources.default)
      })
      .catch((error) => {
        callback(error, null)
      })
  }))
  .init({
    // react: {
    //     useSuspense: false
    // },
    lng: localStorage.getItem('language') ? localStorage.getItem('language') : "en",
    fallbackLng: "en",
     // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
