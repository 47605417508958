/* @flow */
import R from 'ramda';
import { getUserRoles } from '../components/temp/auth';

export default function isSpaCm(): boolean {
  const roles = getUserRoles();
  if (roles) {
    const rolesList = roles.split(',');
    return (
      R.contains('3', rolesList) &&
      R.contains('4', rolesList) &&
      true
    );
  }
  return false;
}
