/* @flow */
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import config from '../config';

export default function getCognitoUserPool() {
  return new CognitoUserPool({
    UserPoolId: config.cognitoUserPoolId,
    ClientId: config.cognitoClientId,
  });
}
