/* @flow */
import R from "ramda";
import { toGlobalId } from "./common/util";

const cognitoIdsForStages = {
  dev: {
    cognitoClientId: "30lsqfkoipn3fem0ojoih5lik9",
    cognitoIdentityPoolId: "us-east-1:4e8bca08-cc3e-443b-b2f5-e0ec802611e4",
    cognitoUserPoolId: "us-east-1_S552heOhI",
  },

  "localhost:3002": {
    cognitoClientId: "30lsqfkoipn3fem0ojoih5lik9",
    cognitoIdentityPoolId: "us-east-1:4e8bca08-cc3e-443b-b2f5-e0ec802611e4",
    cognitoUserPoolId: "us-east-1_S552heOhI",
  },
  demo: {
    cognitoClientId: "2e38usr0ss8hs3bgdnfmscpcfp",
    cognitoIdentityPoolId: "us-east-1:23c5a286-febf-4a56-87cc-3cfaa767f3f8",
    cognitoUserPoolId: "us-east-1_P0lF2XYqW",
  },
  qa: {
    cognitoClientId: "5cgos2dr1skeh29v5fp4faas72",
    cognitoIdentityPoolId: "us-east-1:b1de0dde-0f67-4379-8af9-080982938388",
    cognitoUserPoolId: "us-east-1_yUthpyaLC",
  },
  // pointing QA to PROD Cognito
  //   qa: {
  //     cognitoClientId: "4faqrsv43mjd96rstkh8442n3o",
  //     cognitoIdentityPoolId: "us-east-1:b5f1b7d8-b135-47f4-beff-21cdcf8cadb5",
  //     cognitoUserPoolId: "us-east-1_bHN98sAHB",
  //   },
  train: {
    cognitoClientId: "6d5qltcfr9ok1j35r1dto5c4ml",
    cognitoIdentityPoolId: "us-east-1:c1c3f676-078d-46b2-999f-a5d46ef521a9",
    cognitoUserPoolId: "us-east-1_Y27fZaLfH",
  },
  partners: {
    cognitoClientId: "2m9tvd9av4rbiqb4u8flc3hbcu",
    cognitoIdentityPoolId: "us-east-1:4e8bca08-cc3e-443b-b2f5-e0ec802611e4",
    cognitoUserPoolId: "us-east-1_7lOeAuNtB",
  },
  sb: {
    cognitoClientId: "665k9q3jdt301nsl49csarbnf0",
    cognitoIdentityPoolId: "us-east-1:66c429d2-3032-4103-8e8d-869dd5e82186",
    cognitoUserPoolId: "us-east-1_gR8Qd2XXg",
  },
  prod: {
    cognitoClientId: "4faqrsv43mjd96rstkh8442n3o",
    cognitoIdentityPoolId: "us-east-1:b5f1b7d8-b135-47f4-beff-21cdcf8cadb5",
    cognitoUserPoolId: "us-east-1_bHN98sAHB",
  },
};

const apiGatewayEndpointForCognitoResend = (stageName) => {
  if (stageName === "qa") {
    return `https://84xdoyfr4i.execute-api.us-east-1.amazonaws.com/${stageName}/cognitoconfirmationcode`;
  }
  if (stageName === "prod") {
    return `https://cky8vde1lh.execute-api.us-east-1.amazonaws.com/${stageName}/cognitoconfirmationcode`;
  }
  if (stageName === "localhost:3002") {
    return `https://br05k1iq3b.execute-api.us-east-1.amazonaws.com/dev/cognitoconfirmationcode`;
  }
  if (stageName === "demo") {
    return `https://7a3ot17j8g.execute-api.us-east-1.amazonaws.com/${stageName}/cognitoconfirmationcode`;
  }
  if (stageName === "train") {
    return `https://32pt0wyqvi.execute-api.us-east-1.amazonaws.com/${stageName}/cognitouser`;
  }
  return `https://br05k1iq3b.execute-api.us-east-1.amazonaws.com/${stageName}/cognitoconfirmationcode`;
};

const apiGatewayUrlForStages = (stageName, nonGovCloud) => {
  if (stageName === "qa" && !nonGovCloud) {
    return `https://erxakfefgj.execute-api.us-gov-west-1.amazonaws.com/${stageName}`;
  }
  if (stageName === "train" && !nonGovCloud) {
    return `https://ehtlclluck.execute-api.us-east-1.amazonaws.com/${stageName}`;
  }

  if (stageName === "localhost:3002" && !nonGovCloud) {
    return `http://localhost:3000`;
  }
  if (stageName === "dev" && !nonGovCloud) {
    return `https://fj1lz1hfsk.execute-api.us-east-1.amazonaws.com/${stageName}`;
  }
  if (stageName === "prod" && !nonGovCloud) {
    return `https://iqt3gg70dd.execute-api.us-gov-west-1.amazonaws.com/${stageName}`;
  }
  if (stageName === "demo" && !nonGovCloud) {
    return `https://3pdkjghg22.execute-api.us-east-1.amazonaws.com/${stageName}`;
  }
  return `https://xcjk54wenc.execute-api.us-east-1.amazonaws.com/${stageName}`;
};

const wsApiGatewayUrlForStages = (stageName) => {
  if (stageName === "prod") {
    return `wss://1zuptznxf2.execute-api.us-gov-west-1.amazonaws.com/${stageName}`;
  }
  if (stageName === "qa") {
    return `wss://1bh6vexoc9.execute-api.us-gov-west-1.amazonaws.com/${stageName}`;
  }
  if (stageName === "localhost:3002") {
    return `ws://localhost:3000`;
  }
  if (stageName === "demo") {
    return `wss://aiyhmckjt4.execute-api.us-east-1.amazonaws.com/${stageName}`;
  }
  if (stageName === "train") {
    return `wss://6msp2hvljg.execute-api.us-east-1.amazonaws.com/${stageName}`;
  }
  return `wss://nky7aksdqi.execute-api.us-east-1.amazonaws.com/${stageName}`;
};

const videoCallEndpointsForStages = (stageName) => {
  if (stageName === "qa") {
    return `https://84xdoyfr4i.execute-api.us-east-1.amazonaws.com/qa`;
  }
  if (stageName === "train") {
    return `https://32pt0wyqvi.execute-api.us-east-1.amazonaws.com/train`;
  }

  if (stageName === "localhost:3002") {
    return `http://localhost:3000`;
  }
  if (stageName === "dev") {
    return `https://br05k1iq3b.execute-api.us-east-1.amazonaws.com/dev`;
  }
  if (stageName === "prod") {
    return `https://cky8vde1lh.execute-api.us-east-1.amazonaws.com/prod`;
  }
  if (stageName === "demo") {
    return `https://7a3ot17j8g.execute-api.us-east-1.amazonaws.com/demo`;
  }
};

const apiGatewayEndpointForCognitoSignup = (stageName) => {
  if (stageName === "qa") {
    return `https://84xdoyfr4i.execute-api.us-east-1.amazonaws.com/${stageName}/cognitouser`;
  }
  if (stageName === "prod") {
    return `https://cky8vde1lh.execute-api.us-east-1.amazonaws.com/${stageName}/cognitouser`;
  }

  if (stageName === "localhost:3002") {
    return `https://localhost:3000/dev/cognitouser`;
  }
  if (stageName === "demo") {
    return `https://7a3ot17j8g.execute-api.us-east-1.amazonaws.com/${stageName}/cognitouser`;
  }
  if (stageName === "train") {
    return `https://32pt0wyqvi.execute-api.us-east-1.amazonaws.com/${stageName}/cognitouser`;
  }
  return `https://br05k1iq3b.execute-api.us-east-1.amazonaws.com/${stageName}/cognitouser`;
};

const domainsForStages = {
  prod: ["mypokket.com"],
  demo: [
    "mypokket-demo.com",
    "d395skci8kzpso.cloudfront.net",
    "showme.acivilate.com",
  ],
  "localhost:3002": ["localhost:3002"],
  qa: ["mypokket-qa.com", "d1mebgx1s31m05.cloudfront.net", "qa.acivilate.com"],
  partners: ["mypokket-partners.com123", "dbemosk8m3r90.cloudfront.net123"],
  sb: [
    "mypokket-sb.com",
    "d11bxy5d18b4vv.cloudfront.net",
    "tryme.acivilate.com",
  ],
  train: ["mypokket-train.com", "train.acivilate.com"],
};

export const EULA_family = "Default"; // set to "NCDAC" in NC repo

function getStage(host) {
  const testHostInStage = (stageName) => {
    return R.any(
      (domain) => R.test(new RegExp(domain), host),
      domainsForStages[stageName]
    );
  };
  const stageName =
    R.find(testHostInStage, Object.keys(domainsForStages)) || "dev";
  return stageName;
}
const testHostInStage = (host) => (stageName) => {
  return R.any(
    (domain) => R.test(new RegExp(domain), host),
    domainsForStages[stageName]
  );
};

export function getEnvSpecificConfiguration(host) {
  const stage =
    R.find(testHostInStage(host), Object.keys(domainsForStages)) || "dev";
  const cognitoIds = cognitoIdsForStages[stage];
  const awsRegion = "us-east-1";

  return {
    stage,
    signupEndpoint: apiGatewayEndpointForCognitoSignup(stage),
    resendEndpoint: apiGatewayEndpointForCognitoResend(stage),
    graphQlApiEndpoint: `${apiGatewayUrlForStages(stage)}/graphql`,
    serverUtilitiesEndpoint: `${apiGatewayUrlForStages(stage)}/serverutilities`,
    wsApiEndpoint: `${wsApiGatewayUrlForStages(stage)}`,
    searchEndPoint: `${apiGatewayUrlForStages(stage)}/Search`,
    usernameEndpoint: `${apiGatewayUrlForStages(stage)}/usernameexists`,
    verifyStatusEndpoint: `${apiGatewayUrlForStages(stage)}/verifystatus`,
    reportingEndpoint: `${apiGatewayUrlForStages(stage)}/reports`,
    analyticsEndpoint: `${apiGatewayUrlForStages(stage)}/analytics`,
    pokketsearch: `${apiGatewayUrlForStages(stage)}/pokketsearch`,
    signedurlEndpoint: `${apiGatewayUrlForStages(stage)}/signedurl`,
    createUserEndpoint: `${apiGatewayUrlForStages(stage, true)}/createuser`,
    createVideoCallEndpoint: `${videoCallEndpointsForStages(stage)}/joincall`,
    endVideoCallEndpoint: `${videoCallEndpointsForStages(stage)}/endcall`,
    videoCallLogEndpoint: `${apiGatewayUrlForStages(stage)}/videoCallLog`,
    logToLambdaEndpoint: `${apiGatewayUrlForStages(stage)}/logToLambda`,
    CognitoAdminEndpoint: `${apiGatewayUrlForStages(stage, true)}/cognitoadmin`,
    s3ProfileImageBucketName: `mypokket-${stage}.com`,
    s3ThumbnailBucket: `mypokket-docs-tb-${stage}`,
    s3DocsBucketName: `mypokket-docs-${stage}`,
    cognitoIdProviderKey: `cognito-idp.${awsRegion}.amazonaws.com/${cognitoIds.cognitoUserPoolId}`,
    ...cognitoIds,
  };
}

export function getActionableNotificationLink(notif, viewerType) {
  const viewerIsSPAMode = window.location.href.indexOf("/admin/") !== -1;
  const {
    documentNodeId,
    notificationType,
    notificationFrom,
    facilityId,
    serviceId,
    planItemTypeId,
    taskNodeId,
    notificationFromParticipantNodeId,
    announcementNodeId,
    participantReferralNodeId,
    concernedParticipantNodeId,
    notificationActionRefId,
    notificationActionRefNodeId,
  } = notif;
  let id = notif.notificationFromParticipantNodeId;
  let actionableNotificationLink = "/";
  if (viewerType === "ServiceProviderAdmin" && viewerIsSPAMode) {
    actionableNotificationLink = `${actionableNotificationLink}admin/`;
  }
  if (viewerType === "Participant") {
    actionableNotificationLink = `${actionableNotificationLink}me/`;
  }
  if (notificationType === "PARTICIPANT_REFERRAL") {
    // for now just re-direct to the dashboard until we figure out a solution
    // to prevent multiple SPAs clicking on the notification and starting the referral process.
    // id = toGlobalId('ParticipantReferral', `${notificationFrom}|${facilityId}|${serviceId}`);
    const referralApplicantNodeId = toGlobalId(
      "ReferralApplicant",
      notificationFrom
    );
    actionableNotificationLink = `${actionableNotificationLink}referral/${referralApplicantNodeId}/${participantReferralNodeId}`;
  }
  // TODO implement this functionality later
  // if (notificationType === 'PARTICIPANT_ASSIGNED') {
  //   actionableNotificationLink = `${actionableNotificationLink}participants/${id}`;
  // }
  if (notificationType === "CM_CREATES_PLAN_ITEM") {
    if (planItemTypeId === "TASK") {
      actionableNotificationLink = `${actionableNotificationLink}plan/tasks/${taskNodeId}`;
    } else {
      actionableNotificationLink = `${actionableNotificationLink}plan`;
    }
  }

  if (notificationType === "MANDATORY_TASK_COMPLETION") {
    if (planItemTypeId === "TASK") {
      actionableNotificationLink = `${actionableNotificationLink}participants/${id}/plan/tasks/${taskNodeId}`;
    } else {
      actionableNotificationLink = `${actionableNotificationLink}participants/${id}/plan`;
    }
  }

  if (
    notificationType === "PARTICIPANT_SHARE" ||
    notificationType === "PARTICIPANT_UNSHARE"
  ) {
    if (viewerType === "Participant") {
      if (planItemTypeId === "TASK") {
        actionableNotificationLink = `${actionableNotificationLink}plan/tasks/${taskNodeId}`;
      } else {
        actionableNotificationLink = `${actionableNotificationLink}plan`;
      }
    } else {
      if (planItemTypeId === "TASK") {
        actionableNotificationLink = `${actionableNotificationLink}participants/${id}/plan/tasks/${taskNodeId}`;
      } else {
        actionableNotificationLink = `${actionableNotificationLink}participants/${id}/plan`;
      }
    }
  }
  if (
    notificationType === "PLAN_TEMPLATE_APPLIED_TO_PARTICIPANT" &&
    viewerType === "Participant"
  ) {
    actionableNotificationLink = `${actionableNotificationLink}plan`;
  }
  if (
    notificationType === "APPOINTMENT_CANCELATION" ||
    notificationType === "APPOINTMENT_REQUEST" ||
    notificationType === "APPOINTMENT_REQUEST_ACCEPTED" ||
    notificationType === "APPOINTMENT_REQUEST_REJECTED" ||
    notificationType === "APPOINTMENT_RESCHEDULE" ||
    notificationType === "APPOINTMENT_RESCHEDULE_DENIED" ||
    notificationType === "APPOINTMENT_RESCHEDULE_REQUESTED"
  ) {
    if (viewerType === "Participant") {
      id = notif.notificationFromCaseManagerNodeId;
      actionableNotificationLink = `${actionableNotificationLink}contacts/${id}/appointments/details/${notif.notificationActionRefNodeId}`;
    } else {
      id = notif.notificationFromParticipantNodeId;
      actionableNotificationLink = `${actionableNotificationLink}participants/${id}/appointments/details/${notif.notificationActionRefNodeId}`;
    }
  }
  if (notificationType === "PARTICIPANT_DOCUMENT_SHARE") {
    actionableNotificationLink = `${actionableNotificationLink}participants/${notificationFromParticipantNodeId}/documents/${documentNodeId}/view`;
  }
  if (
    notificationType === "CM_DOCUMENT_SHARE" ||
    notificationType === "DOCUMENT_QUARANTINED"
  ) {
    actionableNotificationLink = `${actionableNotificationLink}documents/${documentNodeId}/view`;
  }
  if (notificationType === "BROADCAST_ANNOUNCEMENT") {
    actionableNotificationLink = `${actionableNotificationLink}announcement/${announcementNodeId}`;
  }
  if (
    notificationType === "PARTICIPANT_CHANGED_PHYSICAL_ADDRESS" ||
    notificationType === "PARTICIPANT_CHANGED_MAILING_ADDRESS" ||
    notificationType === "PARTICIPANT_CHANGED_PRIMARY_PHONE_NUMBER" ||
    notificationType === "PARTICIPANT_CHANGED_EMAIL_ADDRESS"
  ) {
    if (viewerType === "Participant") {
      actionableNotificationLink = `${actionableNotificationLink}profile/contact`;
    } else {
      actionableNotificationLink = `${actionableNotificationLink}participants/${id}/contact`;
    }
  }
  if (notificationType === "PARTICIPANT_EDITED_WORK_HISTORY") {
    if (viewerType === "Participant") {
      actionableNotificationLink = `${actionableNotificationLink}profile/workhistory`;
    } else {
      actionableNotificationLink = `${actionableNotificationLink}participants/${id}/work_history`;
    }
  }
  if (notificationType === "NEW_PENDING_REFERRAL_FOR_PARTICIPANT") {
    actionableNotificationLink = `${actionableNotificationLink}profile/viewservices`;
  }
  if (notificationType === "SERVICE_PROVIDER_ACCEPTED_INITIATED_REFERRAL") {
    actionableNotificationLink = `${actionableNotificationLink}participants/${concernedParticipantNodeId}/viewservices/referral/${notificationActionRefNodeId}`;
  }
  if (notificationType === "SERVICE_PROVIDER_REJECTED_INITIATED_REFERRAL") {
    actionableNotificationLink = `${actionableNotificationLink}participants/${concernedParticipantNodeId}/viewservices/referral/${notificationActionRefNodeId}`;
  }
  if (notificationType === "PARTICIPANT_ACCEPTED_REFERRAL_INITIATION") {
    actionableNotificationLink = `${actionableNotificationLink}participants/${id}/viewservices/referral/${notificationActionRefNodeId}`;
  }
  if (notificationType === "PARTICIPANT_DECLINED_REFERRAL_INITIATION") {
    actionableNotificationLink = `${actionableNotificationLink}participants/${id}/viewservices/referral/${notificationActionRefNodeId}`;
  }
  if (notificationType === "PARTICIPANT_CHECKIN_UNSHARE") {
    actionableNotificationLink = `${actionableNotificationLink}participants/${id}/checkins`;
  }
  if (notificationType === "PARTICIPANT_CHECKIN_SHARE") {
    actionableNotificationLink = `${actionableNotificationLink}participants/${id}/checkins`;
  }
  if (notificationType === "PARTICIPANT_BEING_ONBOARDED") {
    const serviceNodeId = toGlobalId("Service", serviceId);
    actionableNotificationLink = `${actionableNotificationLink}org/services/${serviceId}/${serviceNodeId}/referralhistory/${participantReferralNodeId}`;
  }
  return actionableNotificationLink;
}

const host = window ? window.location.host : "dev";
//const host = window ? window.location.host : 'localhost';

export const stageName = getStage(host);

export default {
  ...getEnvSpecificConfiguration(host),
  awsRegion: "us-east-1",
  localStorageAuthenticatedUserKey: "authenticatedUser",
  inactivityIntervalInSeconds: 1800,
  defaultErrorMessage: "An unspecified error occurred.",
  communityCourtId: "2Yb144eYE",
  physicalAddressType: "PHYSICAL",
  mailingAddressType: "MAILING",
  organizationActiveStatus: 4,
  maintenanceMode: false,
  participantPlanItemType: { GOAL: 1, OBJECTIVE: 2, TASK: 3 },
  namePrefixes: [
    { description: "SELECT PREFIX", value: "" },
    { description: "Ms", value: "Ms." },
    { description: "Mrs", value: "Mrs." },
    { description: "Mr", value: "Mr." },
    { description: "Master", value: "Master" },
    { description: "Rev", value: "Rev." },
    { description: "Fr (Father)", value: "Fr." },
    { description: "Dr (Doctor)", value: "Dr." },
  ],
  nameSuffixes: [
    { description: "SELECT SUFFIX", value: "" },
    { description: "Jr.", value: "Jr." },
    { description: "Sr.", value: "Sr." },
    { description: "II", value: "II" },
    { description: "III", value: "III" },
    { description: "IV", value: "IV" },
  ],
  races: [
    { description: "SELECT ETHNIC BACKGROUND", value: "0" },
    { description: "American Indian or Alaska Native", value: "8" },
    { description: "Asian", value: "5" },
    { description: "Black or African American", value: "6" },
    { description: "Hispanic or Latino", value: "7" },
    { description: "Native Hawaiian or Other Pacific Islander", value: "3" },
    { description: "White", value: "1" },
    { description: "Biracial", value: "2" },
    { description: "Multiracial", value: "4" },
    { description: "Other", value: "9" },
  ],
  householdIncomes: [
    { description: "SELECT HOUSEHOLD INCOME", value: "0" },
    { description: "under $25,000", value: "1" },
    { description: "$25,000 - $30,000", value: "2" },
    { description: "$30,000 - $40,000", value: "3" },
    { description: "$40,000 - $50,000", value: "4" },
    { description: "$50,000 - $75,000", value: "5" },
    { description: "$75,000 - $100,000", value: "6" },
    { description: "over $100,000", value: "7" },
  ],
  genders: [
    { description: "SELECT GENDER", value: "" },
    { description: "Female", value: "Female" },
    { description: "Male", value: "Male" },
    { description: "Other", value: "Other" },
  ],
  timeSlots: [
    { description: "12:00 AM", value: "00" },
    { description: "1:00 AM", value: "01" },
    { description: "2:00 AM", value: "02" },
    { description: "3:00 AM", value: "03" },
    { description: "4:00 AM", value: "04" },
    { description: "5:00 AM", value: "05" },
    { description: "6:00 AM", value: "06" },
    { description: "7:00 AM", value: "07" },
    { description: "8:00 AM", value: "08" },
    { description: "9:00 AM", value: "09" },
    { description: "10:00 AM", value: "10" },
    { description: "11:00 AM", value: "11" },
    { description: "12:00 PM", value: "12" },
    { description: "1:00 PM", value: "13" },
    { description: "2:00 PM", value: "14" },
    { description: "3:00 PM", value: "15" },
    { description: "4:00 PM", value: "16" },
    { description: "5:00 PM", value: "17" },
    { description: "6:00 PM", value: "18" },
    { description: "7:00 PM", value: "19" },
    { description: "8:00 PM", value: "20" },
    { description: "9:00 PM", value: "21" },
    { description: "10:00 PM", value: "22" },
    { description: "11:00 PM", value: "23" },
  ],
  durations: [
    { description: "15 minutes", value: "15" },
    { description: "30 minutes", value: "30" },
    { description: "45 minutes", value: "45" },
    { description: "1 hour", value: "60" },
    { description: "1 hour 30 minutes", value: "90" },
    { description: "2 hours", value: "120" },
  ],
  educationalLevels: [
    { description: "SELECT EDUCATIONAL LEVEL ATTAINED", value: "" },
    { description: "1st - 4th grade", value: "1st - 4th grade" },
    { description: "5th - 6th grade", value: "5th - 6th grade" },
    { description: "7th - 8th grade", value: "7th - 8th grade" },
    { description: "9th grade", value: "9th grade" },
    { description: "10th grade", value: "10th grade" },
    { description: "11th grade", value: "11th grade" },
    { description: "High school graduate", value: "High school graduate" },
    {
      description: "Some college, no degree",
      value: "Some college, no degree",
    },
    {
      description: "Associate's degree, occupational",
      value: "Associate's degree, occupational",
    },
    {
      description: "Associate's degree, academic",
      value: "Associate's degree, academic",
    },
    { description: "Bachelor's degree", value: "Bachelor's degree" },
    { description: "Master's degree", value: "Master's degree" },
    { description: "Professional degree", value: "Professional degree" },
    { description: "Doctoral degree", value: "Doctoral degree" },
  ],
  payScales: [
    { description: "Free", value: "1" },
    { description: "Sliding Scale", value: "2" },
    { description: "Insurance Required", value: "3" },
    { description: "Insurance Accepted", value: "4" },
    { description: "Fee for Service", value: "5" },
  ],
  needCategories: [
    { description: "SELECT NEED CATEGORY", value: "" },
    { description: "Home", value: "1", iconUrl: "/assets/images/nc/1.svg" },
    { description: "Health", value: "2", iconUrl: "/assets/images/nc/2.svg" },
    {
      description: "Emotional",
      value: "3",
      iconUrl: "/assets/images/nc/3.svg",
    },
    { description: "ID", value: "4", iconUrl: "/assets/images/nc/4.svg" },
    {
      description: "Education",
      value: "5",
      iconUrl: "/assets/images/nc/5.svg",
    },
    { description: "Clothing", value: "6", iconUrl: "/assets/images/nc/6.svg" },
    {
      description: "Social",
      value: "7",
      iconUrl: "/assets/images/nc/7.svg",
    },
    { description: "Jobs", value: "8", iconUrl: "/assets/images/nc/8.svg" },
    {
      description: "Spiritual",
      value: "9",
      iconUrl: "/assets/images/nc/9.svg",
    },
    { description: "Legal", value: "10", iconUrl: "/assets/images/nc/10.svg" },
    {
      description: "Transport",
      value: "11",
      iconUrl: "/assets/images/nc/11.svg",
    },
    { description: "Food", value: "12", iconUrl: "/assets/images/nc/12.svg" },
    { description: "Phone", value: "13", iconUrl: "/assets/images/nc/13.svg" },
    { description: "Other", value: "14", iconUrl: "/assets/images/nc/14.svg" },
  ],
  serviceHourReasons: [
    { description: "SELECT REASON", value: "" },
    { description: "Initial sentence", value: "+Initial sentence" },
    {
      description: "Modification - add hours",
      value: "+Modification - add hours",
    },
    {
      description: "Modification - subtract hours",
      value: "Modification - subtract hours",
    },
    { description: "Hours performed", value: "Hours performed" },
  ],
  usStates: [
    { name: "SELECT STATE", abbreviation: "" },
    { name: "Alabama", abbreviation: "AL" },
    { name: "Alaska", abbreviation: "AK" },
    { name: "American Samoa", abbreviation: "AS" },
    { name: "Arizona", abbreviation: "AZ" },
    { name: "Arkansas", abbreviation: "AR" },
    { name: "California", abbreviation: "CA" },
    { name: "Colorado", abbreviation: "CO" },
    { name: "Connecticut", abbreviation: "CT" },
    { name: "Delaware", abbreviation: "DE" },
    { name: "District Of Columbia", abbreviation: "DC" },
    { name: "Federated States Of Micronesia", abbreviation: "FM" },
    { name: "Florida", abbreviation: "FL" },
    { name: "Georgia", abbreviation: "GA" },
    { name: "Guam", abbreviation: "GU" },
    { name: "Hawaii", abbreviation: "HI" },
    { name: "Idaho", abbreviation: "ID" },
    { name: "Illinois", abbreviation: "IL" },
    { name: "Indiana", abbreviation: "IN" },
    { name: "Iowa", abbreviation: "IA" },
    { name: "Kansas", abbreviation: "KS" },
    { name: "Kentucky", abbreviation: "KY" },
    { name: "Louisiana", abbreviation: "LA" },
    { name: "Maine", abbreviation: "ME" },
    { name: "Marshall Islands", abbreviation: "MH" },
    { name: "Maryland", abbreviation: "MD" },
    { name: "Massachusetts", abbreviation: "MA" },
    { name: "Michigan", abbreviation: "MI" },
    { name: "Minnesota", abbreviation: "MN" },
    { name: "Mississippi", abbreviation: "MS" },
    { name: "Missouri", abbreviation: "MO" },
    { name: "Montana", abbreviation: "MT" },
    { name: "Nebraska", abbreviation: "NE" },
    { name: "Nevada", abbreviation: "NV" },
    { name: "New Hampshire", abbreviation: "NH" },
    { name: "New Jersey", abbreviation: "NJ" },
    { name: "New Mexico", abbreviation: "NM" },
    { name: "New York", abbreviation: "NY" },
    { name: "North Carolina", abbreviation: "NC" },
    { name: "North Dakota", abbreviation: "ND" },
    { name: "Northern Mariana Islands", abbreviation: "MP" },
    { name: "Ohio", abbreviation: "OH" },
    { name: "Oklahoma", abbreviation: "OK" },
    { name: "Oregon", abbreviation: "OR" },
    { name: "Palau", abbreviation: "PW" },
    { name: "Pennsylvania", abbreviation: "PA" },
    { name: "Puerto Rico", abbreviation: "PR" },
    { name: "Rhode Island", abbreviation: "RI" },
    { name: "South Carolina", abbreviation: "SC" },
    { name: "South Dakota", abbreviation: "SD" },
    { name: "Tennessee", abbreviation: "TN" },
    { name: "Texas", abbreviation: "TX" },
    { name: "Utah", abbreviation: "UT" },
    { name: "Vermont", abbreviation: "VT" },
    { name: "Virgin Islands", abbreviation: "VI" },
    { name: "Virginia", abbreviation: "VA" },
    { name: "Washington", abbreviation: "WA" },
    { name: "West Virginia", abbreviation: "WV" },
    { name: "Wisconsin", abbreviation: "WI" },
    { name: "Wyoming", abbreviation: "WY" },
  ],
};
