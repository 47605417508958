import React from 'react';
import { Route } from 'react-router';
import Loadable from 'react-loadable';
import Loading from '../common/loading/Loading';

const LoadableStaticEula = Loadable({
  loader: () => import('../common/onboarding/SignEULA'),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} static />;
  }
});

const LoadablePrivacy = Loadable({
  loader: () => import('../components/general/privacy'),
  loading: Loading,
});

const staticRoutes = (
  <div>
    <Route path="eula" component={() => <LoadableStaticEula />} />
    <Route path="privacy" component={LoadablePrivacy} />
  </div>
    );

export default staticRoutes;
