import React from 'react';
import { Route, IndexRedirect } from 'react-router';
import Loadable from 'react-loadable';
import Loading from '../common/loading/Loading';


const LoadableResetTempPassword = Loadable({
  loader: () => import('../common/onboarding/ResetTempPassword'),
  loading: Loading,
});

const LoadableContactInfo = Loadable({
  loader: () => import('../wilfred/profile/ContactInfo'),
  loading: Loading,
});

const LoadableProfilePicture = Loadable({
  loader: () => import('../wilfred/profile/ProfilePicture'),
  loading: Loading,
});

const LoadableChangePhoneNumber = Loadable({
  loader: () => import('../common/change-phone/ChangePhoneNumber'),
  loading: Loading,
});

const LoadableSwitchRoles = Loadable({
  loader: () => import('../common/switch-roles/SwitchRoles'),
  loading: Loading,
});

const LoadableSettings = Loadable({
  loader: () => import('../common/settings/Settings'),
  loading: Loading,
});

// Route group for common self-profile screens
// all users have Contact Info, Profile Picture, and Reset Password
export default (
  <div>
    <IndexRedirect to="contact" />
    <Route path="settings" component={LoadableSettings} />
    <Route path="changepassword" component={LoadableResetTempPassword} />
    <Route path="contact" component={LoadableContactInfo} />
    <Route path="profilepicture" component={LoadableProfilePicture} />
    <Route path="changephonenumber" component={LoadableChangePhoneNumber} />
    <Route path="switchroles" component={LoadableSwitchRoles} />
  </div>
);

// objects used to configure links Sidebar in SelfProfiles
// prefix /admin, or /me for SPA or Participant, respectively
export const commonLinksConfig = (prefix: string = '') => [
  {
    value: 'contact_info',
    label: 'CONTACT INFORMATION',
    path: () => `${prefix}/profile/contact`,
    description: 'Your email, addresses, and phone numbers',
  },
  {
    value: 'profile_picture',
    label: 'PROFILE PICTURE',
    path: () => `${prefix}/profile/profilepicture`,
    description: 'Upload or change your profile picture',
  },
  {
    value: 'change_password',
    label: 'CHANGE PASSWORD',
    path: () => `${prefix}/profile/changepassword`,
    description: 'Change your Pokket login password',
  },
  {
    value: 'change_phone',
    label: 'CHANGE CELL PHONE NUMBER',
    path: () => `${prefix}/profile/changephonenumber`,
    description: 'Change your cell phone number',
  },
];
