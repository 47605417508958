/* eslint-disable max-len */
import React from "react";
import { Route, IndexRoute } from "react-router";
import Loadable from "react-loadable";
import Loading from "../common/loading/Loading";
import selfProfileRoutes from "./selfProfileRoutes";

const LoadableProfilesContainer = Loadable({
  loader: () => import("../case-mgr/profiles/ProfilesContainer"),
  loading: Loading,
});

const LoadableParticipantList = Loadable({
  loader: () => import("../components/participantList"),
  loading: Loading,
});

const LoadableParticipantDashboard = Loadable({
  loader: () => import("../components/ParticipantDashboard"),
  loading: Loading,
});

const LoadableParticipantProfile = Loadable({
  loader: () => import("../case-mgr/profiles/ProfileHeader"),
  loading: Loading,
});

const LoadableUserContactInfo = Loadable({
  loader: () => import("../case-mgr/profiles/UserContactInfo"),
  loading: Loading,
});

const LoadableUserDemographicInfo = Loadable({
  loader: () => import("../case-mgr/profiles/UserDemographicInfo"),
  loading: Loading,
});

const LoadableViewParticipantServices = Loadable({
  loader: () => import("../common/user-profiles/ViewParticipantServices"),
  loading: Loading,
});

const LoadableViewReferral = Loadable({
  loader: () => import("../common/referrals/ParticipantViewReferral"),
  loading: Loading,
});

const LoadableParticipantCommunityService = Loadable({
  loader: () => import("../components/participantCommunityService"),
  loading: Loading,
});

const LoadableSharedDocumentsList = Loadable({
  loader: () => import("../case-mgr/view-documents/SharedDocumentsList"),
  loading: Loading,
});

const LoadableOrgDocumentsList = Loadable({
  loader: () => import("../case-mgr/view-documents/OrgDocumentsList"),
  loading: Loading,
});

const LoadableUserDocumentViewer = Loadable({
  loader: () => import("../common/files/UserDocumentViewer"),
  loading: Loading,
});

const LoadableParticipantTasksViewer = Loadable({
  loader: () => import("../case-mgr/view-plans/ParticipantTasksViewer"),
  loading: Loading,
});

const LoadableParticipantCustodyStatus = Loadable({
  loader: () =>
    import("../case-mgr/profiles/custody-status/ParticipantCustodyStatus"),
  loading: Loading,
});

const LoadableParticipantCustodyStatusHistory = Loadable({
  loader: () =>
    import(
      "../case-mgr/profiles/custody-status/ParticipantCustodyStatusHistory"
    ),
  loading: Loading,
});

const LoadableCaseManagerAdminActions = Loadable({
  loader: () => import("../case-mgr/admin/CaseManagerAdminActions"),
  loading: Loading,
});

const LoadableCheckInViewer = Loadable({
  loader: () => import("../common/view-checkins/CheckInViewer"),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.cmCheckInViewer;
    return <Component {...props} />;
  },
});

const LoadableMessageThreads = Loadable({
  loader: () => import("../common/messages/MessageThreads"),
  loading: Loading,
});

const LoadableMessageExchange = Loadable({
  loader: () => import("../common/messages/MessageExchange"),
  loading: Loading,
});

const LoadableVideo = Loadable({
  loader: () => import("../common/video/VideoScreen"),
  loading: Loading,
});

// const LoadableCheckInViewer = Loadable({
//   loader: () => import('../common/view-checkins/CheckInViewer'),
//   loading: Loading,
// });

const LoadableAdminContainer = Loadable({
  loader: () => import("../case-mgr/admin/AdminContainer"),
  loading: Loading,
});

/* const LoadableCreateLogin = Loadable({
  loader: () => import('../common/create-login/CreateLogin'),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.cmCreateLogin;
    return <Component {...props}/>;
  }
}); */

const LoadableCreateLogin = Loadable({
  loader: () => import("../common/create-login/CreateLogin"),
  loading: Loading,
});

const LoadableRecoverTempPassword = Loadable({
  loader: () => import("../common/create-login/RecoverTempPassword"),
  loading: Loading,
});

const LoadableSendInviteToSPA = Loadable({
  loader: () => import("../case-mgr/invite-sp/SendInviteToSPA"),
  loading: Loading,
});

const LoadableProvidersSearchResults = Loadable({
  loader: () => import("../case-mgr/search-sps/ProvidersSearchResults"),
  loading: Loading,
});

const LoadableProvidersSearchForm = Loadable({
  loader: () => import("../case-mgr/search-sps/ProvidersSearchForm"),
  loading: Loading,
});

const LoadableReporting = Loadable({
  loader: () => import("../common/reporting/Reporting"),
  loading: Loading,
});

const LoadableAvailabilityConfigContainer = Loadable({
  loader: () =>
    import("../case-mgr/appointments/containers/AvailabilityConfigContainer"),
  loading: Loading,
});

const LoadableSwitchRoles = Loadable({
  loader: () => import("../common/switch-roles/SwitchRoles"),
  loading: Loading,
});

const LoadableBackstop = Loadable({
  loader: () => import("../case-mgr/backstop/BackstoppingCMs"),
  loading: Loading,
});

const LoadableCaseManagerSelfProfile = Loadable({
  loader: () => import("../case-mgr/CaseManagerSelfProfile"),
  loading: Loading,
});

const LoadableCMAssignmentsOwnProfile = Loadable({
  loader: () => import("../common/user-profiles/CMAssignmentsOwnProfile"),
  loading: Loading,
});

const LoadableAppointmentsContainer = Loadable({
  loader: () => import("../common/appointments/AppointmentsContainer"),
  loading: Loading,
});

const LoadableAppointmentsList = Loadable({
  loader: () => import("../common/appointments/AppointmentsList"),
  loading: Loading,
});

const LoadableOldAppointmentsList = Loadable({
  loader: () => import("../common/appointments/OldAppointmentsList"),
  loading: Loading,
});

const LoadableAppointment = Loadable({
  loader: () => import("../common/appointments/Appointment"),
  loading: Loading,
});

const LoadableMultiUserAppointment = Loadable({
  loader: () => import("../common/appointments/MultiUserAppointment"),
  loading: Loading,
});

const LoadableIncomingAppointmentRequests = Loadable({
  loader: () => import("../common/appointments/IncomingAppointmentRequests"),
  loading: Loading,
});

/* const LoadableTreatmentPlanContainer = Loadable({
  loader: () => import('../case-mgr/view-plans/containers/TreatmentPlanContainer'),
  loading: Loading,
});

const LoadableTreatmentPlanTopLevel = Loadable({
  loader: () => import('../case-mgr/view-plans/containers/TreatmentPlanTopLevel'),
  loading: Loading,
});

const LoadableTreatmentPlanTaskLevel = Loadable({
  loader: () => import('../case-mgr/view-plans/containers/TreatmentPlanTaskLevel'),
  loading: Loading,
});

const LoadableTreatmentPlanTaskFileManager = Loadable({
  loader: () => import('../wilfred/plan/TreatmentPlanTaskFileManager'),
  loading: Loading,
}); */

const LoadableTreatmentPlan = Loadable({
  loader: () => import("../wilfred/plan/TreatmentPlan"),
  loading: Loading,
});

const LoadableTreatmentPlanTopLevel = Loadable({
  loader: () => import("../wilfred/plan/all-goals/TreatmentPlanTopLevel"),
  loading: Loading,
});

const LoadableOrgTreatmentPlanTopLevel = Loadable({
  loader: () => import("../wilfred/plan/all-goals/OrgTreatmentPlanTopLevel"),
  loading: Loading,
});

const LoadableTreatmentPlanTaskLevel = Loadable({
  loader: () => import("../wilfred/plan/task-details/TreatmentPlanTaskLevel"),
  loading: Loading,
});

const LoadableTreatmentPlanTaskFileManager = Loadable({
  loader: () => import("../wilfred/plan/TreatmentPlanTaskFileManager"),
  loading: Loading,
});

const LoadableWorkHistory = Loadable({
  loader: () => import("../case-mgr/views/CMViewingParticipantWorkHistory"),
  loading: Loading,
});

const LoadableCaseNotes = Loadable({
  loader: () => import("../common/case-notes/CaseNotes"),
  loading: Loading,
});

const LoadableCalendar = Loadable({
  loader: () => import("../common/calendar/CalendarCMPView"),
  loading: Loading,
});

const LoadableCreateCaseNote = Loadable({
  loader: () => import("../common/case-notes/CreateCaseNote"),
  loading: Loading,
});

const LoadableEditCaseNote = Loadable({
  loader: () => import("../common/case-notes/EditCaseNote"),
  loading: Loading,
});

const LoadableAnnouncements = Loadable({
  loader: () => import("../common/announcements/Announcements"),
  loading: Loading,
});

const LoadableCreateAnnouncement = Loadable({
  loader: () => import("../common/announcements/CreateAnnouncement"),
  loading: Loading,
});

const LoadableTemplates = Loadable({
  loader: () => import("../common/templates/Templates"),
  loading: Loading,
});

const LoadableUploadFiles = Loadable({
  loader: () => import("../case-mgr/upload-files/UploadFilesByCM"),
  loading: Loading,
});

const LoadableAnalyticsList = Loadable({
  loader: () => import("../common/reporting/analytics/AnalyticsLanding"),
  loading: Loading,
});

const LoadableAnalyticsReport = Loadable({
  loader: () => import("../common/reporting/analytics/AnalyticsReport"),
  loading: Loading,
});

const LoadableTestError = Loadable({
  loader: () => import("../common/test-error/TestError"),
  loading: Loading,
});

/* const planRoutes = (
  <Route path="plan" component={LoadableTreatmentPlanContainer} >
    <IndexRoute component={LoadableTreatmentPlanTopLevel} />
    <Route path="tasks/:goalid" component={LoadableTreatmentPlanTaskLevel} />
    <Route path="tasks/:goalid/documents" component={LoadableTreatmentPlanTaskFileManager} />
  </Route>
  ); */
const planRoutes = (
  <Route path="plan" component={LoadableTreatmentPlan}>
    <IndexRoute component={LoadableTreatmentPlanTopLevel} />
    <Route path="tasks/:taskid" component={LoadableTreatmentPlanTaskLevel} />
    <Route
      path="tasks/:taskid/documents"
      component={LoadableTreatmentPlanTaskFileManager}
    />
  </Route>
);

const spPlanRoutes = (
  <Route path="plan/orgitems" component={LoadableTreatmentPlan}>
    <IndexRoute component={LoadableOrgTreatmentPlanTopLevel} />
  </Route>
);

const appointmentRoutes = (
  <Route path="appointments" component={LoadableAppointmentsContainer}>
    <IndexRoute component={LoadableAppointmentsList} />
    <Route path="past" component={LoadableOldAppointmentsList} />
    <Route path="details" component={LoadableTreatmentPlan}>
      <Route path=":appointmentId" component={LoadableAppointment} />
    </Route>
    <Route path="requests" component={LoadableIncomingAppointmentRequests} />
  </Route>
);

const connectedParticipantsRouter = (
  <Route path="/participants" component={LoadableProfilesContainer}>
    <Route path=":id" component={LoadableParticipantDashboard}>
      <IndexRoute component={LoadableParticipantProfile} />
      <Route
        path="messagingwith/:pokketUserId"
        component={LoadableMessageThreads}
      />
      <Route
        archived={true}
        path="archivedmessagingwith/:pokketUserId"
        component={LoadableMessageThreads}
      />
      <Route
        path="messageexchange/:threadId"
        component={LoadableMessageExchange}
      />
      <Route path="contact" component={LoadableUserContactInfo} />
      <Route path="viewservices" component={LoadableViewParticipantServices} />
      <Route
        path="viewservices/referral/:referralId"
        component={LoadableViewReferral}
      />
      <Route path="demographics" component={LoadableUserDemographicInfo} />
      <Route
        path="comunityservice"
        component={LoadableParticipantCommunityService}
      />
      <Route path="documents" component={LoadableSharedDocumentsList} />
      <Route path="org/documents" component={LoadableOrgDocumentsList} />
      <Route
        path="documents/:documentId/view"
        component={LoadableUserDocumentViewer}
      />
      <Route path="dashboard" component={LoadableParticipantTasksViewer} />
      {planRoutes}
      {spPlanRoutes}
      {appointmentRoutes}
      <Route
        path="custody_status"
        component={LoadableParticipantCustodyStatus}
      />
      <Route
        path="custody_status_history"
        component={LoadableParticipantCustodyStatusHistory}
      />
      <Route path="checkins" component={LoadableCheckInViewer} />
      <Route path="work_history" component={LoadableWorkHistory} />
      <Route path="case_notes" component={LoadableCaseNotes} />
      <Route path="calendar" component={LoadableCalendar} />
      <Route
        path="create_case_note/:pokketUserId"
        component={LoadableCreateCaseNote}
      />
      <Route
        path="edit_case_note/:noteId/:pokketUserId"
        component={LoadableEditCaseNote}
      />
    </Route>
  </Route>
);

// TODO change to options/appointments or something
const adminRoutes = {
  indexRoute: "/cm-admin",
  createNewParticipantLogin: "createlogin",
  recoverTempPassword: "recover-pw",
};

const adminRouter = (
  <Route path={adminRoutes.indexRoute} component={LoadableAdminContainer}>
    <IndexRoute component={LoadableCaseManagerAdminActions} />
    <Route
      path={adminRoutes.createNewParticipantLogin}
      component={LoadableCreateLogin}
    />
    <Route
      path={adminRoutes.recoverTempPassword}
      component={LoadableRecoverTempPassword}
    />
    <Route path="invite-sp" component={LoadableSendInviteToSPA} />
    <Route
      path="search-sp/results"
      component={LoadableProvidersSearchResults}
    />
    <Route path="search-sp" component={LoadableProvidersSearchForm} />
    <Route path="reporting" component={LoadableReporting} />
    <Route path="analytics" component={LoadableAnalyticsList} />
    <Route path="analytics/:id" component={LoadableAnalyticsReport} />
    <Route path="templates" component={LoadableTemplates} />
    <Route path="upload-files" component={LoadableUploadFiles} />
  </Route>
);

const caseMgrRoutes = (
  <Route>
    <Route path="test-error" component={LoadableTestError} />
    <Route
      path="/appointments/config"
      component={LoadableAvailabilityConfigContainer}
    />
    <Route path="/chooserole" component={LoadableSwitchRoles} />
    <Route path="/backstop" component={LoadableBackstop} />
    <Route path="/profile" component={LoadableCaseManagerSelfProfile}>
      {selfProfileRoutes}
      <Route path="cmassignments" component={LoadableCMAssignmentsOwnProfile} />
    </Route>
    <Route path="/announcements" component={LoadableAnnouncements} />
    <Route path="/createannouncement" component={LoadableCreateAnnouncement} />
    <Route path="/appointment" component={LoadableAppointmentsContainer}>
      <Route path=":appointmentId" component={LoadableMultiUserAppointment} />
    </Route>
    {adminRouter}
    {connectedParticipantsRouter}
  </Route>
);

export default caseMgrRoutes;
