// all copy has been translated
import React from 'react';
import { useTranslation } from 'react-i18next';


const LoadingMessage = () => {
  const { t, i18n } = useTranslation('nav/nav');
  return (<div>{t('Loading')}</div>);
}

const ErrorMessage = () => {
  const { t, i18n } = useTranslation('nav/nav');
  return (<p>{t('ErrorMessage')}</p>);  // this is "Sorry, an error has occurred."
}

const ErrorWithMessage = (props) => {
  const { t, i18n } = useTranslation('nav/nav');
  return (<p>{t('Error')} {props.message} </p>); // // this is "Error!" followed by props
}

export { LoadingMessage, ErrorMessage, ErrorWithMessage }
