import React from 'react';
import { Route, IndexRoute, IndexRedirect } from 'react-router';
import Loadable from 'react-loadable';
import Loading from '../common/loading/Loading';
import selfProfileRoutes from './selfProfileRoutes';
import staticRoutes from './staticRoutes';
import FacilityView from '../sp-admin/sp-tree/FacilityView';
import ServiceView from '../sp-admin/sp-tree/ServiceView';

// TODO reimplement as something that extends ProfileScaffold
function SPAContactsContainer(props) {
  return (
    <div>
      {props.children}
    </div>
  );
}

function OrgProfileContainer(props) {
  return (
    <div>
      {props.children}
    </div>
  );
}

const LoadableCMAssignments = Loadable({
  loader: () => import('../common/user-profiles/CMAssignments'),
  loading: Loading,
});

const LoadableCMAssignmentsOwnProfile = Loadable({
  loader: () => import('../common/user-profiles/CMAssignmentsOwnProfile'),
  loading: Loading,
});

const LoadableCreateFacility = Loadable({
  loader: () => import('../components/psd/createFacility'),
  loading: Loading,
});

const LoadableCreateLogin = Loadable({
  loader: () => import('../common/create-login/CreateLogin'),
  loading: Loading,
});

const LoadableCreateService = Loadable({
  loader: () => import('../components/psd/createService'),
  loading: Loading,
});

const LoadableEditFacility = Loadable({
  loader: () => import('../components/psd/editFacility'),
  loading: Loading,
});

const LoadableEditService = Loadable({
  loader: () => import('../components/psd/editService'),
  loading: Loading,
});

const LoadableViewFacility = Loadable({
  loader: () => import('../components/psd/viewFacility'),
  loading: Loading,
});

const LoadableViewService = Loadable({
  loader: () => import('../components/psd/viewService'),
  loading: Loading,
});

const LoadableFacilitiesAndServices = Loadable({
  loader: () => import('../components/facilitiesAndServices'),
  loading: Loading,
});

const LoadableNotifications = Loadable({
  loader: () => import('../common/notifications/Notifications'),
  loading: Loading,
});

const LoadableReferralIntakeAnswers = Loadable({
  loader: () => import('../sp-admin/referral-requests/ReferralIntakeAnswers'),
  loading: Loading,
});

const LoadableReferralApplicantProfile = Loadable({
  loader: () => import('../sp-admin/referral-requests/ReferralApplicantProfile'),
  loading: Loading,
});

const LoadableServiceProviderTree = Loadable({
  loader: () => import('../sp-admin/sp-tree/ServiceProviderTree'),
  loading: Loading,
});

/*
const LoadableServiceOrFacilityView = Loadable({
    loader: () => import("../sp-admin/sp-tree/ServiceOrFacility"),
    laoding: Loading,
})
*/

const LoadableSPAdminContainer = Loadable({
  loader: () => import('../sp-admin/SPAdminContainer'),
  loading: Loading,
});

const LoadableSPAdminDashboard = Loadable({
  loader: () => import('../sp-admin/SPAdminDashboard'),
  loading: Loading,
});

const LoadableSPManagePermissions = Loadable({
  loader: () => import('../sp-admin/sp-profile/SPManagePermissions'),
  loading: Loading,
});

const LoadableSPManageStatus = Loadable({
  loader: () => import('../sp-admin/sp-profile/SPManageStatus'),
  loading: Loading,
});

const LoadableSPViewPermissions = Loadable({
  loader: () => import('../sp-admin/sp-profile/SPViewPermissions'),
  loading: Loading,
});

const LoadableSPReviewPage = Loadable({
  loader: () => import('../sp-admin/sp-review/SPReviewPage'),
  loading: Loading,
});

const LoadableSwitchRoles = Loadable({
  loader: () => import('../common/switch-roles/SwitchRoles'),
  loading: Loading,
});

const LoadableEditOrgProfile = Loadable({
  loader: () => import('../sp-admin/org-profile/EditOrgProfile'),
  loading: Loading,
});

const LoadableSPAdminSelfProfile = Loadable({
  loader: () => import('../sp-admin/SPAdminSelfProfile'),
  loading: Loading,
});

const LoadableOnboardingNextSteps = Loadable({
  loader: () => import('../sp-admin/docs/OnboardingNextSteps'),
  loading: Loading,
});

const LoadableManageFacilityPersonnel = Loadable({
  loader: () => import('../sp-admin/manage-personnel/ManageFacilityPersonnel'),
  loading: Loading,
});

const LoadableManageServicePersonnel = Loadable({
  loader: () => import('../sp-admin/manage-personnel/ManageServicePersonnel'),
  loading: Loading,
});

const LoadableMessageThreads = Loadable({
  loader: () => import('../common/messages/MessageThreads'),
  loading: Loading,
});

const LoadableMessageExchange = Loadable({
  loader: () => import('../common/messages/MessageExchange'),
  loading: Loading,
});
const LoadableSelectPeople = Loadable({
  loader: () => import('../common/messages/SelectPeople'),
  loading: Loading,
});

const LoadableProfile = Loadable({
  loader: () => import('../sp-admin/user-profile/Profile'),
  loading: Loading,
});

const LoadableUserContactInfo = Loadable({
  loader: () => import('../sp-admin/user-profile/UserContactInfo'),
  loading: Loading,
});

const LoadableUserProfilePicture = Loadable({
  loader: () => import('../sp-admin/user-profile/UserProfilePicture'),
  loading: Loading,
});

const LoadableUserDemographicInfo = Loadable({
  loader: () => import('../sp-admin/user-profile/UserDemographicInfo'),
  loading: Loading,
});

const LoadableUserCaseload = Loadable({
  loader: () => import('../sp-admin/user-profile/cm/ManageCaseload'),
  loading: Loading,
});

const LoadableUserFacilitiesServices = Loadable({
  loader: () => import('../sp-admin/user-profile/cm/FacilitiesServices'),
  loading: Loading,
});

const LoadableUserFacilitiesServicesOwnProfile = Loadable({
  loader: () => import('../sp-admin/user-profile/cm/FacilitiesServicesOwnProfile'),
  loading: Loading,
});

const LoadableUserStatus = Loadable({
  loader: () => import('../sp-admin/user-profile/ManageUser'),
  loading: Loading,
});

const LoadableSPFacilitiesAndServices = Loadable({
  loader: () => import('../sp-admin/sp-profile/SPFacilitiesAndServices'),
  loading: Loading,
});

const LoadableReassign = Loadable({
  loader: () => import('../sp-admin/reassign/Reassign'),
  loading: Loading,
});

const LoadableReassignForSpecificCM = Loadable({
  loader: () => import('../sp-admin/reassign/ReassignForSpecificCM'),
  loading: Loading,
});

const LoadableReporting = Loadable({
  loader: () => import('../common/reporting/Reporting'),
  loading: Loading,
});

const LoadableAnalyticsList = Loadable({
  loader: () => import('../common/reporting/analytics/AnalyticsLanding'),
  loading: Loading,
});

const LoadableAnalyticsReport = Loadable({
  loader: () => import('../common/reporting/analytics/AnalyticsReport'),
  loading: Loading,
});

const LoadableBulkRelease = Loadable({
  loader: () => import('../sp-admin/bulk-release/BulkRelease'),
  loading: Loading,
});


const LoadableEditSPOrgProfile = Loadable({
  loader: () => import('../sp-admin/sp-profile/EditSPOrgProfile'),
  loading: Loading,
});

const LoadableViewFacilityPersonnel = Loadable({
  loader: () => import('../sp-admin/manage-personnel/ViewFacilityPersonnel'),
  loading: Loading,
});

const LoadableViewServicePersonnel = Loadable({
  loader: () => import('../sp-admin/manage-personnel/ViewServicePersonnel'),
  loading: Loading,
});

const LoadableViewSPOrgProfile = Loadable({
  loader: () => import('../sp-admin/sp-profile/ViewSPOrgProfile'),
  loading: Loading,
});

const LoadableViewParticipantServices = Loadable({
  loader: () => import('../common/user-profiles/ViewParticipantServices'),
  loading: Loading,
});

const LoadableParticipantCustodyStatus = Loadable({
  loader: () => import('../case-mgr/profiles/custody-status/ParticipantCustodyStatus'),
  loading: Loading,
});

const LoadableParticipantCustodyStatusHistory = Loadable({
  loader: () => import('../case-mgr/profiles/custody-status/ParticipantCustodyStatusHistory'),
  loading: Loading,
});

const LoadableServiceReferralHistory = Loadable({
  loader: () => import('../sp-admin/sp-review/SPServiceReferralHistory'),
  loading: Loading,
});

const LoadableServiceReferral = Loadable({
  loader: () => import('../sp-admin/sp-review/ServiceReferral'),
  loading: Loading,
});

const LoadableHelpVideos = Loadable({
  loader: () => import('../common/help-videos/HelpVideos'),
  loading: Loading,
});

const LoadableGuardianDependents = Loadable({
  loader: () => import('../sp-admin/guardian-dependents/GuardianDependents'),
  loading: Loading,
});

const docsRouteGroup = (
  <div>
    <Route path="docs" component={OrgProfileContainer}>
      <Route path="next-steps" component={LoadableOnboardingNextSteps} />
    </Route>
  </div>
);

const spaRoutes = (loginner) => (
  <Route path="/admin" onEnter={loginner} component={LoadableSPAdminContainer} >
    <IndexRedirect to="home" />
    <Route path="profile" component={LoadableSPAdminSelfProfile} >
      {selfProfileRoutes}
      <Route path="cmassignments" component={LoadableCMAssignmentsOwnProfile} />
      <Route path="facilitiesservices" component={LoadableUserFacilitiesServicesOwnProfile} />
    </Route>
    {docsRouteGroup}
    {staticRoutes}
    <Route path="chooserole" component={LoadableSwitchRoles} />
    <Route path="home" component={LoadableSPAdminDashboard} />
    <Route path="manageorg" component={LoadableServiceProviderTree} />
    <Route path="manageorg/facility/:id" component={FacilityView} />
    <Route path="manageorg/service/:id" component={ServiceView} />
    <Route path="reassign" component={LoadableReassign} />
    <Route path="helpvideos" component={LoadableHelpVideos} />
    <Route path="reassign/:id" component={LoadableReassignForSpecificCM} />
    <Route path="reporting" component={LoadableReporting} />
    <Route path="analytics" component={LoadableAnalyticsList} />
    <Route path="analytics/:id" component={LoadableAnalyticsReport} />
    <Route path="bulkrelease" component={LoadableBulkRelease} />
    <Route path="switchroles" component={LoadableSwitchRoles} />
    <Route path="referral/:referralApplicantNodeId/:id" component={LoadableReferralApplicantProfile} />
    <Route path="referral/:referralApplicantNodeId/:id/finalize" component={LoadableReferralIntakeAnswers} />
    <Route path="createlogin" component={LoadableCreateLogin} />
    <Route path="org-profile" component={OrgProfileContainer}>
      <IndexRoute component={() => <div>Index Route</div>} />
      <Route path="edit" component={LoadableEditOrgProfile} />
      <Route path=":id/edit" component={LoadableEditSPOrgProfile} />
      <Route path=":id/viewprofile" component={LoadableViewSPOrgProfile} />
    </Route>
    <Route path="org">
      <Route path=":id/managepermissions" component={LoadableSPManagePermissions} />
      <Route path=":id/managestatus" component={LoadableSPManageStatus} />
      <Route path=":id/viewpermissions" component={LoadableSPViewPermissions} />
      <Route path=":id/review" component={LoadableSPReviewPage} />
      <Route path=":id/facilities/create" component={LoadableCreateFacility} />
      <Route path=":id/services/create" component={LoadableCreateService} />
      <Route path="facilities/:id" component={LoadableEditFacility} />
      <Route path="facilities/:id/view" component={LoadableViewFacility} />
      <Route path="facilities/:id/personnel" component={LoadableManageFacilityPersonnel} />
      <Route path="facilities/:id/viewpersonnel" component={LoadableViewFacilityPersonnel} />
      <Route path="services" component={LoadableFacilitiesAndServices} />
      <Route path=":id/services" component={LoadableSPFacilitiesAndServices} />
      <Route path="services/:id" component={LoadableEditService} />
      <Route path="services/:id/view" component={LoadableViewService} />
      <Route path="services/:id/personnel" component={LoadableManageServicePersonnel} />
      <Route path="services/:id/viewpersonnel" component={LoadableViewServicePersonnel} />
      <Route path="services/:id/:serviceNodeId/referralhistory" component={LoadableServiceReferralHistory} />
      <Route path="services/:id/:serviceNodeId/referralhistory/:referralId" component={LoadableServiceReferral} />
    </Route>
    <Route path="contacts/:id" component={SPAContactsContainer}>
      <IndexRoute component={() => <div>Profile Page For SPA Connections</div>} />
      <Route path="edit-info" component={() => <div>Editor for contactinfo</div>} />
    </Route>
    <Route path="notifications" component={LoadableNotifications} />
    <Route path="messages" component={LoadableMessageThreads} />
    <Route archived={true} path="archivedmessages" component={LoadableMessageThreads} />
    <Route path="messageexchange/:id" component={LoadableMessageExchange} />
    <Route path="messages/selectpeople/:id/:pokketUserId" component={LoadableSelectPeople} />
    <Route path="profile/:id" component={LoadableProfile} >
      <IndexRedirect to="contact" />
      <Route path="contact" component={LoadableUserContactInfo} />
      <Route path="profilepicture" component={LoadableUserProfilePicture} />
      <Route path="demographics" component={LoadableUserDemographicInfo} />
      <Route path="manageuser" component={LoadableUserStatus} />
      {/* <Route path="managecaseload" component={LoadableUserCaseload} /> */}
      <Route path="facilitiesservices" component={LoadableUserFacilitiesServices} />
      <Route path="guardian-dependents" component={LoadableGuardianDependents} />
      <Route path="cmassignments" component={LoadableCMAssignments} />
      <Route path="viewservices" component={LoadableViewParticipantServices} />
      <Route path="custody_status" component={LoadableParticipantCustodyStatus} />
      <Route path="custody_status_history" component={LoadableParticipantCustodyStatusHistory} />
    </Route>

  </Route>
);

export default spaRoutes;
