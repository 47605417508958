import React from 'react';
import { Route } from 'react-router';
import Loadable from 'react-loadable';
import Loading from '../common/loading/Loading';

const LoadableLogin = Loadable({
  loader: () => import('../components/temp/Login'),
  loading: Loading,
});
const LoadablePasswordRecovery = Loadable({
  loader: () => import('../common/recover-password/PasswordRecovery'),
  loading: Loading,
});


const loginRoutes = (
  <div>
    <Route path="/login" component={LoadableLogin} />
    <Route path="/loginhelp" component={LoadablePasswordRecovery} />
  </div>
    );

export default loginRoutes;
