/* @flow */
import moment from 'moment';
// import type { Task } from '../wilfred/dashboard/DashboardTasks.jsx';
type Task = Object;

export function flattenConnection<T>(c?: Connection<T>): T[] {
  if (c === undefined) {
    return [];
  }
  const edges = c.edges || [];
  return edges.map(({ node }) => node);
}

// filename formatter for plan item documents
// a. participantPokketUserId/participantPlanItemId/servicePlanItemDocumentId/fileName
// b. participantPokketUserId/participantPlanItemId/fileName
// c. participantPokketUserId/fileName <- ignore for now

type PlanDocument = {
  participantPokketUserId: string,
  participantPlanItemId: string,
  servicePlanItemDocumentId?: string,
  fileName: string,
};

export function planDocumentFilename(props: PlanDocument): string {
  let key: string;
  const {
    participantPokketUserId,
    participantPlanItemId,
    servicePlanItemDocumentId,
    fileName,
  } = props;

  // user and plan item id is required
  key = `${participantPokketUserId}/${participantPlanItemId}`;

  // servicePlanItemDocumentId is optinally in third position (but requires plan item id)
  key = servicePlanItemDocumentId ? `${key}/${servicePlanItemDocumentId}` : key;

  // file name is at the end
  key = `${key}/${fileName}`;

  return key;
}

// TODO deal with nulls
export function compareDate(t1: Task, t2: Task): number {
  const d1 = moment(t1.dueDate, 'MM-DD-YY').unix();
  const d2 = moment(t2.dueDate, 'MM-DD-YY').unix();
  return d1 - d2;
}

export function removeDuplicatesByProp(originalArray, prop) {
     var newArray = [];
     var lookupObject  = {};

     for(var i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
     }

     for(i in lookupObject) {
         newArray.push(lookupObject[i]);
     }
      return newArray;
 }

export const toGlobalId = (type, id) => {
  const string =  [type, id].join(':');
  return btoa(string);
}