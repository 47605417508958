import React, { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import shortid from "shortid";
import { hashHistory, Link } from "react-router";
import UPDATE_SERVICE_STATUS from "../../graphql/mutations/UpdateServiceStatus";
import { inject, observer } from "mobx-react";
import {
  FaChevronRight,
  FaChevronDown,
  FaPencil,
  FaTrashCan,
  FaUsers,
} from "react-icons/fa6";
import HeaderText from "../../common/typography/HeaderText";
import {
  LoadingMessage,
  ErrorMessage,
} from "../../common/loading/LoadingMessage";
import Button from "../../common/rebass-custom/Button";

const GET_SERVICE = gql`
  query GetService($id: ID!) {
    node(id: $id) {
      ... on Service {
        id
        name
        needCategory
        serviceId
        status
        facilities {
          id
          name
          facilityId
          status
        }
        caseManagers {
          firstName
          lastName
          pokketUserId
          id
        }
      }
    }
  }
`;

function ServiceComponent(props) {
  const [serviceNode, setServiceNode] = useState({
    id: "",
    name: "",
    status: "",
    needCategory: "",
    serviceId: "",
    facilities: "",
    caseManagers: "",
  });
  const [servicesExpanded, setServicesExpanded] = useState(false);
  const [cmsExpanded, setCmsExpanded] = useState(false);
  const [deleteService] = useMutation(UPDATE_SERVICE_STATUS);

  const { name, status, serviceId, services, caseManagers } = serviceNode;
  const displayAppName = process.env.APP_LABEL;

  const { loading, error, data } = useQuery(GET_SERVICE, {
    variables: { id: props.params.id },
  });

  useEffect(() => {
    if (!data) return;
    initService();
      
  }, [data]);

  function initService() {
    setServiceNode({
      name: data.node.name,
      status: data.node.status,
      serviceId: data.node.serviceId,
      facilities: data.node.facilities.sort((a, b) => a.name.localeCompare(b.name)),      
      caseManagers: data.node.caseManagers.sort((a, b) => a.lastName.localeCompare(b.lastName))
    });
  }

  const handleDeleteService = async () => {
    const response = await props.supermodal.showConfirm();

    if (response) {
      props.supermodal.showWaiting();
      try {
        await deleteService({
          variables: {
            input: {
              statusId: 5 /* RETIRED */,
              serviceId: serviceId,
              clientMutationId: shortid.generate(),
            },
          },
        });
        props.supermodal.flashSuccess("Service removed");
        return;
      } catch (err) {
        console.log(err);
        logToLambda(
          "ERROR",
          "src/sp-admin/facility/Facility.jsx/handleDeleteFacility", // TODO: change path
          err.message,
          `${window.location.href}`,
          "mutation"
        );
        props.supermodal.flashFailure();
        return;
      }
    }
    return;
  };

  if (loading) {
    return <LoadingMessage />;
  }

  if (error) {
    return <ErrorMessage />;
  }

  return (
    <DocumentTitle title={`${serviceNode.name} - ${displayAppName}`}>
      <>
        <HeaderText mb={3}>{serviceNode.name}</HeaderText>

        {/* Managing Buttons */}
        {status === "RETIRED" ? (
          <p>This Service has been removed.</p>
        ) : (
          <div className="flex flex-wrap mb3">
            <Button
              mr={2}
              mb={2}
              onClick={() =>
                hashHistory.push(`/admin/org/services/${props.params.id}`)
              }
            >
              <div className="flex center">
                <FaPencil className="mr2" />
                <span>Edit Service</span>
              </div>
            </Button>
            <Button
              mr={2}
              mb={2}
              onClick={() =>
                hashHistory.push(
                  `/admin/org/services/${props.params.id}/personnel`
                )
              }
            >
              <div className="flex center">
                <FaUsers className="mr2" />
                <span>Manage Personnel</span>
              </div>
            </Button>
            <Button mb={2} orange="true" onClick={handleDeleteService}>
              <div className="flex center">
                <FaTrashCan className="mr2" />
                <span>Delete Service</span>
              </div>
            </Button>
          </div>
        )}

        {/* Services and Case Managers */}
        <div className="cf">
          <div className="fl w-100 w-50-ns mb3">
            <h2
              className="f3 mb3 pointer flex center"
              onClick={() => setServicesExpanded(!servicesExpanded)}
            >
              {servicesExpanded ? <FaChevronDown /> : <FaChevronRight />}
              {
                <span className="ml2">
                  {serviceNode.facilities.length} Facilities
                </span>
              }
            </h2>
            {servicesExpanded && (
              <ul>
                {serviceNode.facilities.map((s) => (
                  <li key={s.id} className="mb2 hover-brand-green">
                    <Link to={`/admin/manageorg/facility/${s.id}`}>
                      {s.name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="fl w-100 w-50-ns">
            <h2
              className="f3 mb3 pointer flex center"
              onClick={() => setCmsExpanded(!cmsExpanded)}
            >
              {cmsExpanded ? <FaChevronDown /> : <FaChevronRight />}
              <span className="ml2">
                {serviceNode.caseManagers.length} Case Managers
              </span>
            </h2>
            {cmsExpanded && (
              <ul>
                {serviceNode.caseManagers.map((c) => (
                  <li key={c.id} className="mb2 hover-brand-green">
                    <Link to={`/admin/profile/${c.id}/contact`}>
                      {c.lastName}, {c.firstName}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </>
    </DocumentTitle>
  );
}

const ServiceView = inject("supermodal")(
    observer((props) => {
        return <ServiceComponent {...props} />;
    })
);

export default ServiceView;
