import React from 'react';
import { Route, IndexRedirect, Redirect } from 'react-router';
import Loadable from 'react-loadable';
import Loading from '../common/loading/Loading';
import staticRoutes from './staticRoutes';
import loginRoutes from './loginRoutes';
import participantRoutes from './participantRoutes';
import caseMgrRoutes from './cmRoutes';
import spaRoutes from './spaRoutes';
import config from '../config';

const LoadableCaseManagerDashboard = Loadable({
  loader: () => import('../components/casemanagerdashboard'),
  loading: Loading,
});

const LoadableCreateSpaAccount = Loadable({
  loader: () => import('../sp-admin/invite-intake/create-account/CreateSpaAccount'),
  loading: Loading,
});
const LoadableCredits = Loadable({
  loader: () => import('../components/general/Credits'),
  loading: Loading,
});
const LoadableDashboard = Loadable({
  loader: () => import('../components/containers/dashboard'),
  loading: Loading,
});

const LoadableInviteIntakeForm = Loadable({
  loader: () => import('../sp-admin/invite-intake/InviteIntakeForm'),
  loading: Loading,
});
const LoadableNotifications = Loadable({
  loader: () => import('../common/notifications/Notifications'),
  loading: Loading,
});
const LoadableMessageThreads = Loadable({
  loader: () => import('../common/messages/MessageThreads'),
  loading: Loading,
});
const LoadableMessageExchange = Loadable({
  loader: () => import('../common/messages/MessageExchange'),
  loading: Loading,
});
const LoadableSelectPeople = Loadable({
  loader: () => import('../common/messages/SelectPeople'),
  loading: Loading,
});
const LoadablePrivacy = Loadable({
  loader: () => import('../components/general/privacy'),
  loading: Loading,
});
const LoadableSearchServices = Loadable({
  loader: () => import('../components/psd/searchServices'),
  loading: Loading,
});

const LoadableSignEula = Loadable({
  loader: () => import('../common/onboarding/SignEULA'),
  loading: Loading,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  }
});

const LoadableAckCaseReassign = Loadable({
  loader: () => import('../wilfred/AcknowledgeCaseReassignment'),
  loading: Loading,
});

const LoadableAcknowledgeAutoshare = Loadable({
  loader: () => import('../wilfred/AcknowledgeAutoshare'),
  loading: Loading,
});

const LoadableInitialOptInForExistingUsers = Loadable({
  loader: () => import('../common/onboarding/InitialOptInForExistingUsers'),
  loading: Loading,
});

const LoadableSwitchRoles = Loadable({
  loader: () => import('../common/switch-roles/SwitchRoles'),
  loading: Loading,
});
const LoadableUserDocumentViewer = Loadable({
  loader: () => import('../common/files/UserDocumentViewer'),
  loading: Loading,
});

const LoadableFormViewer = Loadable({
  loader: () => import('../common/forms/FormViewer'),
  loading: Loading,
});

const LoadableUnderConstruction = Loadable({
  loader: () => import('../common/UnderConstruction'),
  loading: Loading,
});

const LoadableHelpVideos = Loadable({
  loader: () => import('../common/help-videos/HelpVideos'),
  loading: Loading,
});

const LoadableLocationTest = Loadable({
  loader: () => import('../LocationTest'),
  loading: Loading,
});
const LoadablePasswordReset = Loadable({
  loader: () => import('../common/onboarding/ResetTempPassword'),
  loading: Loading,
});
const LoadableLogin = Loadable({
  loader: () => import('../components/temp/Login'),
  loading: Loading,
});
const LoadableApplication = Loadable({
  loader: () => import('../components/containers/application'),
  loading: Loading,
});

const LoadableVideoChat = Loadable({
  loader: () => import('../common/video/VideoScreen'),
  loading: Loading,
});

const LoadableMultiVideoChat = Loadable({
  loader: () => import('../common/video/MultiVideoScreen'),
  loading: Loading,
});

const LoadableSignInitialService = Loadable({
  loader: () => import('../wilfred/sign-initial-services/SignInitialService'),
  loading: Loading,
});

const MaybeDashboard = ({ props }) => {
  return props ? <LoadableDashboard {...props} /> : <Loading />;
};


const routes = (loginner, logoutter) => {
  return (<div>
    <Route path="/locationtest" component={LoadableLocationTest} />
    <Route path="/underconstruction" component={LoadableUnderConstruction} />
    <Route path="/" component={!config.maintenanceMode ? LoadableApplication : LoadableUnderConstruction}>
      <Route path="/resetpassword" component={LoadablePasswordReset} />
      {loginRoutes}
      {participantRoutes(loginner)}
      {spaRoutes(loginner)}
      <Route path="/credits" component={LoadableCredits} />
      <Route path="/privacy" component={LoadablePrivacy} />
      <Route path="/join-pokket">
        <Route path="invite/:orgId" component={LoadableInviteIntakeForm} />
        <Route path="create-account/:orgId" component={LoadableCreateSpaAccount} />
        <Redirect from="*" to="/login" />
      </Route>
      <Route path="/signeula" component={LoadableSignEula} />
      <Route path="/acceptinitialservice" component={LoadableSignInitialService} />
      <Route path="/ackcasereassignment" component={LoadableAckCaseReassign} />
      <Route path="/acknowledgeactiveshare" component={LoadableAcknowledgeAutoshare} />
      <Route path="/smsoptin" component={LoadableInitialOptInForExistingUsers} />
      <Route path="unauthorized" component={() => <h1>Unauthorized</h1>} />
      <Route path="/logout" component={LoadableLogin} onEnter={logoutter} />
      <Route path="/me/videocall/:id" onEnter={loginner} component={LoadableVideoChat} />
      <Route path="/multivideocall/:id" onEnter={loginner} component={LoadableMultiVideoChat} />
      <Route path="/me/multivideocall/:id" onEnter={loginner} component={LoadableMultiVideoChat} />
      <Route path="/videocall/:id" onEnter={loginner} component={LoadableVideoChat} />
      <Route onEnter={loginner} component={LoadableDashboard} render={MaybeDashboard}>
        <IndexRedirect to="home" />
        <Route path="/helpvideos" component={LoadableHelpVideos} />
        <Route path="/home" component={LoadableCaseManagerDashboard} />
        <Route path="/notifications" component={LoadableNotifications} />
        <Route path="/messages" component={LoadableMessageThreads} />
        <Route archived={true} path="/archivedmessages" component={LoadableMessageThreads} />
        <Route path="/messageexchange/:id" component={LoadableMessageExchange} />
        <Route path="/messages/selectpeople/:id/:pokketUserId" component={LoadableSelectPeople} />
        <Route path="/organization/services/search" component={LoadableSearchServices} />
        <Route path="/switchroles" component={LoadableSwitchRoles} />
        {staticRoutes}
        {caseMgrRoutes}
        <Route path="/forms/:id/view" component={LoadableFormViewer} />
      </Route>
      <Route path="/documents/:id/view" component={LoadableUserDocumentViewer} />
      
    </Route>
  </div>)};

export default routes;
