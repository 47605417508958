// all copy has been translated
import React from 'react';
import { Flex } from 'reflexbox';
import Button from '../rebass-custom/Button';
import styled from 'styled-components';
import Oval from './Oval';
import logToLambda from '../util/logToLambda';
import { useTranslation } from 'react-i18next';

const Div = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
`;

export default function Loading(props) {
  if (props.error) {
    logToLambda('ERROR','src/common/loading/Loading.jsx/loadable', props.error, `${window.location.href}`,'LOADING');
    const { t, i18n, ready } = useTranslation('nav/nav');
    if (!ready) return "cannot load translations";
    return <div>
      <div className="mb2">{t('RoutingError')}</div>
      <Button onClick={ props.retry }>{t('Retry')}</Button>
      </div>;
  } else if (props.pastDelay) {
    return (
      <Div>
        <Oval width={props.width} height={props.height}/>
      </Div>
    );
  } else {
    return null;
  }
}
