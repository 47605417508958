/* @flow */
export type UserRole = 'case_manager' | 'participant' | 'sp_admin';
//
const onboardingPaths: {[k: UserRole]: Object} = {
  case_manager: {
    signEula: '/signeula',
    ackCaseReassignment: '/ackcasereassignment',
    resetPassword: '/resetpassword?new_user',
    contactInformation: '/profile/contact?new_user',
    profilePicture: '/profile/profilepicture?new_user',
    lastPage: '/home',
    introduceSmsOptIn: '/smsoptin',
  },
  participant: {
    signEula: '/signeula',
    ackCaseReassignment: '/ackcasereassignment',
    acknowledgeAutoshare: '/acknowledgeactiveshare',
    resetPassword: '/resetpassword?new_user',
    contactInformation: '/me/profile/contact?new_user',
    profilePicture: '/me/profile/profilepicture?new_user',
    demographicInfo: '/me/profile/demographics?new_user',
    lastPage: '/me/home',
    introduceSmsOptIn: '/smsoptin',
  },
  sp_admin: {
    signEula: '/signeula',
    ackCaseReassignment: '/ackcasereassignment',
    resetPassword: '/resetpassword?new_user',
    contactInformation: '/admin/profile/contact?new_user',
    profilePicture: '/admin/profile/profilepicture?new_user',
    editOrgProfile: '/admin/org-profile/edit?new_user',
    lastPage: '/admin/docs/next-steps',
    introduceSmsOptIn: '/smsoptin',
  },
};

export default onboardingPaths;
