/* @flow */
import config from "../../config";
import * as auth from "../../components/temp/auth";
import { token } from "../../auth";

function getUserCredentials() {
  return JSON.parse(
    localStorage.getItem(config.localStorageAuthenticatedUserKey) || "null"
  );
}

function getUserId() {
  const authenticatedUser = getUserCredentials();
  if (authenticatedUser) {
    return authenticatedUser.userId;
  }
  return null;
}

export default async function logToLambda(
  logType,
  logSrc,
  logDesc,
  addlDescription,
  moduleName
) {
  try {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", auth.token() || "");
    headers.append("Accept-Language", localStorage.getItem("language") || "en");

    return fetch(config.logToLambdaEndpoint, {
      method: "POST",
      headers,
      body: JSON.stringify({
        token: token(),
        stage: config.stage,
        pokketUserId: getUserId(),
        logType,
        logSrc,
        logDesc,
        addlDescription,
        moduleName,
      }),
    });
  } catch (err) {
    console.log(err);
  }
}
