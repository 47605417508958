import gql from "graphql-tag";

const UPDATE_FACILITY_STATUS = gql`
  mutation updateFacilityStatus($input: UpdateFacilityStatusInput!) {
    updateFacilityStatus(input: $input) {
      ... on UpdateFacilityStatusPayload {
        facility {
          id
          status
          organization {
            id
            ... on ServiceProvider {
              hasFacilites
              hasFacilitiesWithCMs
              hasServicesWithFacilities
            }
          }
        }
      }
    }
  }
`;

export default UPDATE_FACILITY_STATUS;
