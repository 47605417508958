/* eslint-disable max-len, no-alert */
import "core-js/stable";
import "regenerator-runtime/runtime";
/* global AWS, AWSCognito */
import AWS from 'aws-sdk';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Row, Col, Alert } from 'react-bootstrap';
import Button from  './common/rebass-custom/Button';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import { Provider } from 'mobx-react';
import co from 'co';
import moment from 'moment';
// import 'react-select/dist/react-select.css';
import Loadable from 'react-loadable';
import IdleTimer from 'react-idle-timer';
// router
import { Router, applyRouterMiddleware, hashHistory } from 'react-router';
import useScroll from 'react-router-scroll/lib/useScroll';
import Loading from './common/loading/Loading';
import routes from './router';

import createApolloClient from './apolloclient';
import logToLambda from './common/util/logToLambda';

// css
import './assets/sass/custom.scss';
import '../node_modules/react-big-calendar/lib/css/react-big-calendar.css';

import { token, getCurrentSession, logoutUser } from './auth';
// import Application from './components/containers/application';
// import InviteIntakeForm from './sp-admin/invite-intake/InviteIntakeForm';
// import Login from './components/temp/Login';
import authenticateOnEnter from './auth/authenticateOnEnter';
import config, { getEnvSpecificConfiguration } from './config';
import DocumentTitle from 'react-document-title';
import { Translation } from 'react-i18next';
import './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const LoadableIdle = Loadable({
  loader: () => import('./Idle'),
  loading: Loading,
});
const LoadableCountdown = Loadable({
  loader: () => import('./components/countdown'),
  loading: Loading,
});



// AWS Configuration
AWS.config.region = config.awsRegion;
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: config.cognitoIdentityPoolId,
});

// not sure if we need any of this:
// AWSCognito.config.region = 'us-east-1';
// AWSCognito.config.credentials = new AWS.CognitoIdentityCredentials({
//  IdentityPoolId: config.cognitoIdentityPoolId,
// });

// Need to provide placeholder keys unless unauthorised user access is enabled for user pool
// AWSCognito.config.update({ accessKeyId: 'anything', secretAccessKey: 'anything' });

class LogoutNotifier extends React.Component {

  componentDidMount = () => {
    logoutUser();
    hashHistory.push('/login');
  }

  render = () => {
    return (
      <div />
    );
  };
}


const appRouter = (onLogout, onLogin) => {
  const logoutter = () => {
    onLogout();
  };

  const loginner = (nextState, replace) => {
    authenticateOnEnter(nextState, replace);
    onLogin();
  };
  return (
    routes(loginner, logoutter)
  );
};

class AppRouterWrapper extends React.Component {
  constructor() {
    super();
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)

    this.absoluteTimeoutMinutes = (config.inactivityIntervalInSeconds)/60;
    this.inactivityMinutes = this.absoluteTimeoutMinutes - 1;
    this.idleTimeoutCheckInterval = 60*1000;
    this.loggedOut = false;
    this.elapsedIdleTime=0;
    this.state = {
      lastAccessTime: moment(),
      apolloClient: createApolloClient(null),
      showTimeoutAlert:false,
      loggedIn:false,
    };
    this.routes = appRouter(this.logout, this.login);
  }

  _onAction(e) {
    if (this.state.loggedIn && !this.state.showTimeoutAlert && !document.hidden) {
      localStorage.setItem('timeOutAt', moment().add(this.absoluteTimeoutMinutes, 'minutes'));
    } else if (this.state.loggedIn && moment().isAfter(localStorage.getItem('timeOutAt'))) {
      this.setState({showTimeoutAlert:false,loggedIn:false},() =>{
        logToLambda('INFO','src/index.js/onIdle','onIdleLogout','','LOGOUT');
        logoutUser();
        this.loggedOut = true;
        hashHistory.push('/login');
      });
    }
  }

  _onActive(e) {
    // console.log('user is active', e)
  }

  _onIdle(e) {
    this.isIdle();
  }

  timeoutSession = () => {
    logoutUser();
    hashHistory.replace('/login');
  }

  logout = () => {
    this.state.apolloClient.resetStore();
    this.setState({
      sessionExpiredMessageDisplayed: false,
      loggedIn: false,
    }, () => {
      logToLambda('INFO','src/index.js/logout','logout','','LOGOUT');
      logoutUser();
    });
  }

  login = () => {
    if (moment().isAfter(localStorage.getItem('timeOutAt'))) {
      logoutUser();
      hashHistory.replace('/login');
    } else {
      localStorage.setItem('timeOutAt', moment().add(this.absoluteTimeoutMinutes, 'minutes'));
      let currentSession = null;
      const that = this;
      co(function* gen() {
        try {
          currentSession = yield getCurrentSession();
          const apolloClient = createApolloClient(token());
          if (!that.state.loggedIn){
            logToLambda('INFO','src/index.js/login','login', window.location.href,'LOGIN');
          };
          that.setState({
            lastAccessTime: moment(),
            sessionExpiredMessageDisplayed: false,
            loggedIn: true,
            apolloClient,
          });
        } catch (err) {
          console.log(err);
          if (that.state.loggedIn) {
            alert('Your session has ended. You will now be redirected to the login page.');
          }
          logoutUser();
          hashHistory.replace('/login');
        }
      });
    }
  }

  isIdle = () => {
    if (this.state.loggedIn){
      if (localStorage.getItem('timeOutAt') === null || moment().isAfter(localStorage.getItem('timeOutAt'))) {
        this.setState({showTimeoutAlert:false,loggedIn:false},() =>{
          logToLambda('INFO','src/index.js/onIdle','onIdleLogout','','LOGOUT');
          logoutUser();
          this.loggedOut = true;
          hashHistory.push('/login');
        });
      }
      else if (moment().isAfter(moment(localStorage.getItem('timeOutAt')).subtract(1, 'minutes')) && moment().isBefore(localStorage.getItem('timeOutAt'))) {
          this.setState({showTimeoutAlert:true},() =>{
            this.idleTimer.reset();
          });
      } else {
        this.idleTimer.reset();
      }
    }
  }

  continueSession = () => {
    this.setState({showTimeoutAlert:false}, () => {
      localStorage.setItem('timeOutAt', moment().add(this.absoluteTimeoutMinutes, 'minutes'));
    });

  }

  getTimeoutWarningAlertBox = () => {
    const timeoutmessage = `You have been inactive for ${this.inactivityMinutes} minutes.
        Select "Continue" to extend your session or you will be logged out of Pokket automatically;
        any unsaved work will be lost.`;
    let sessionTimeoutWarningPanel = (<Alert className="centered timeout-alert drop-shadow">
      <p className="alertPanelHeading">Session Timeout</p>
      <Row>
        <Col xs={12} md={12}>
          <div className="mb2">{timeoutmessage}</div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <Button onClick={this.continueSession}>Continue</Button>
          <span className="visible-xs visible-sm"><br /></span>
        </Col>
      </Row>
    </Alert>);
    if (!this.state.showTimeoutAlert) {
      sessionTimeoutWarningPanel = <span />;
    }
    return sessionTimeoutWarningPanel;
  }


  render() {

    return(
    <Translation ns="nav/nav">
    {
      (t, { i18n }) => {

      { if(!i18n.isInitialized) {
        i18n.init({
          lng: localStorage.getItem('language') ? localStorage.getItem('language') : "en",
          fallbackLng: "en",
           // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
          // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
          // if you're using a language detector, do not define the lng option
          interpolation: {
            escapeValue: false // react already safes from xss
          }
        });
      } }


        { if(!i18n.isInitialized) {
          return "waiting on init"
          }
        }

    const pokketApp = (<ApolloProvider client={this.state.apolloClient}>
      <ApolloHooksProvider client={this.state.apolloClient}>
      <div>
        {this.getTimeoutWarningAlertBox()}
        <Provider>
          <DocumentTitle title="Pokket">
          <>
            <Router
              history={hashHistory}
            >
              {this.routes}
            </Router>
          </>
          </DocumentTitle>
        </Provider>
      </div>
      </ApolloHooksProvider>
    </ApolloProvider>);

    // install service worker for PWA
    // Learn more about service workers: https://cra.link/PWA
    //serviceWorkerRegistration.unregister();
    serviceWorkerRegistration.register();

return (
  <div>
    <IdleTimer
      ref={ref => { this.idleTimer = ref }}
      element={document}
      onActive={this.onActive}
      onIdle={this.onIdle}
      onAction={this.onAction}
      debounce={1000}
      timeout={1000 * 60}
    />
    {pokketApp}
  </div>
)

}
    }
   </Translation>
);


  }
}

if (module.hot) {
  module.hot.accept();
}

const NewWrapper = () => {
  // <Suspense fallback="waiting suspensefully">
  return (
    <Suspense fallback={<Loading pastDelay />} >
      <AppRouterWrapper />
    </Suspense>
  )
};

ReactDOM.render(<NewWrapper />, document.getElementById('root'));
// ReactDOM.render(<AppRouterWrapper />, document.getElementById('root'));
