/* @flow */
/* eslint-disable max-len */
import sjcl from 'sjcl';
import moment from 'moment';
import { CognitoUser } from 'amazon-cognito-identity-js';
import config from '../../config';
import getCognitoUserPool from '../../cognito/getCognitoUserPool';


function getUserCredentials() {
  return JSON.parse(
    localStorage.getItem(config.localStorageAuthenticatedUserKey) || 'null'
  );
}

function isSessionValid(userToken) {
  const now = moment().utc();
  const payload = userToken.split('.')[1];
  const idtokenExpiration = JSON.parse(sjcl.codec.utf8String.fromBits(sjcl.codec.base64.toBits(payload)));
  return now.isBefore(moment.unix(idtokenExpiration.exp));
}

export function token() {
  const pool = getCognitoUserPool();
  const cognitoUser = pool.getCurrentUser();
  const authenticatedUser = getUserCredentials();
  if (authenticatedUser) {
    const authToken = authenticatedUser.token;
    if (authToken && isSessionValid(authToken)) {
      return authToken;
    }
    return null;
  }
  return null;
}

export function getUserRoles(): ?string {
  const authenticatedUser = getUserCredentials();
  if (authenticatedUser) {
    return authenticatedUser.roles;
  }
  return null;
}

export function getDefaultRole(): ?string {
  return localStorage.getItem('defaultRole');
}

export function getUserId(): ?string {
  const authenticatedUser = getUserCredentials();
  if (authenticatedUser) {
    return authenticatedUser.userId;
  }
  return null;
}


export function getUserRolesFromUserAttributes() {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCognitoUserPool().getCurrentUser();
    cognitoUser.getUserAttributes((err, result) => {
      if (err) {
        reject(err);
      }
      let roles = '';
      for (let i = 0; i < result.length; i += 1) {
        if (result[i].getName() === 'custom:roles') {
          roles = result[i].getValue();
        }
      }
      resolve(roles);
    });
  });
}


export function getCurrentSession() {
  const that = this;
  return new Promise((resolve, reject) => {
    const userPool = getCognitoUserPool();
    if (!userPool.getCurrentUser()) {
      reject('cognitouser not valid');
    } else {
      const userData = {
        Username: userPool.getCurrentUser().username,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject('cannot get session');
        } else if (session.isValid()) {
          const roles = getUserRoles();
          const userId = getUserId();
          const authenticatedUser = JSON.stringify({
            token: session.idToken.jwtToken,
            roles, userId,
          });
          localStorage.setItem(config.localStorageAuthenticatedUserKey, authenticatedUser);
          resolve(session);
        } else {
          reject('invalid session');
        }
      });
    }
  });
}

const SP_ADMIN_ROLE_CODE = '3';
const CM_ROLE_CODE = '4';
const PLUS_CM_ADMIN_ROLE_CODE = '3,4';
export function isParticipant() {
  const userRole = getUserRoles();
  if (userRole !== CM_ROLE_CODE) {
    if (userRole !== SP_ADMIN_ROLE_CODE) {
      if (userRole !== PLUS_CM_ADMIN_ROLE_CODE) {
        return true;
      }
    }
  }
  return false;
}
